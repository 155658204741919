
@import "reset";
@import "font";



body{font-family: "RobotoRegular", sans-serif; background: #efefef;}

// Создаем mixin
@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: 479px) { @content; }
  }
  @else if $media == wide-handhelds {
    @media only screen and (min-width: 480px) and (max-width: 599px) { @content; }
  }
  @else if $media == small-tablets {
    @media only screen and (min-width: 600px) and (max-width: 767px) { @content; }
  }  
  @else if $media == tablets {
    @media only screen and (min-width: 768px) and (max-width: 979px) { @content; }
  }
  @else if $media == display {
    @media only screen and (min-width: 980px) { @content; }
  }  
  @else if $media == display-big {
    @media only screen and (min-width: 1200px) { @content; }
  }
}



@mixin clearfix { &:after {  content: ".";  display: block;  clear: both;  visibility: hidden;  line-height: 0;  height: 0;} }
// @include clearfix;

.clear {clear:both;}
.none {line-height:0;}

@mixin pod-block-100 {
		margin: 0 auto; position: relative; box-sizing: content-box;
		
			// Для телефонов
			@include respond-to(handhelds) { width: 100%;  }
			// Для телефонов с широким экраном
			@include respond-to(wide-handhelds) { width: 100%;  }
			// Для маленьких планшетов
			@include respond-to(small-tablets) { width: 100%;  }
			// Для планшетов
			@include respond-to(tablets) { width: 100%;  }
			// Для экранов
			@include respond-to(display) {max-width:1200px;  }

}

// @include pod-block-100;


@mixin pod-block-adaptive {



 		min-height: 50px;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        zoom: 1;
        *display: inline;
        _height: 50px;
		
	
}
// @include pod-block-adaptive;


// миксин для стилизации начала списков
@mixin counter($var){
	counter-reset: $var;
	> li, > dt{
		&:before{
			content: counter($var);
			content-increment: $var;
		}
	}
}
// @include counter;



//миксин для кнопок
@mixin btn{
 	float: right; margin-right: 15px; background: url(../img/euro_button.png); background-size:cover;color: #fff; border: none; border-radius: 6px; 			    	   
		&:hover{ background: #FC0808 ; background-size:cover;}				    	
}

// @include btn;


//миксин для плавной анимации

@mixin hover-anim {
&:hover{
	transition: all 0.6s;
	-moz-transition: all 0.6s;
	-webkit-transition: all 0.6s;
	-o-transition: all 0.6s;
}			

}


// @include hover-anim;



@import "header";
@import "footer";

@import "catalog";
@import "cart";
@import "stati";
@import "otzuvu";

@import "sm-blue";





a {color:#000;}
.left{ float: left; }
.right{ float: right;}
.mp-o{ margin: 2px 0; padding: 2px 0;}
.center{ text-align: center;}

.block {
	width: 100%; box-sizing: content-box; 
	.pod-block { 

		 margin: 0 auto; position: relative; box-sizing: content-box;
		// Для телефонов
		@include respond-to(handhelds) { width: 100%; };
		// Для телефонов с широким экраном
		@include respond-to(wide-handhelds) { width: 100%; };
		// Для планшетов
		@include respond-to(small-tablets) { width: 100%; };
		@include respond-to(tablets) { width: 100%; };
		// Для экранов
		@include respond-to(display) { max-width: 1200px; };
		
	}
}

.left-50 {width: 49%; float: left;
			.padding { padding: 2% 1% 0% 3%; }

// Для телефонов до 480
	@include respond-to(handhelds) { float: none; width: 100%; };
	// Для телефонов с широким экраном до 767
	@include respond-to(wide-handhelds) { float: none; width: 100%; };
	// Для планшетов до 979
	@include respond-to(tablets) {  };
	// Для экранов от 980
	@include respond-to(display) {  };
		}


.right-50 {width: 49%; float: right;
.padding { padding: 2% 0% 0% 1%;}
			// Для телефонов до 480
			@include respond-to(handhelds) { float: none; width: 100%; };
			// Для телефонов с широким экраном до 767
			@include respond-to(wide-handhelds) { float: none; width: 100%; };


p {line-height: 130%;}

} /* .right-content */


.tur-top {margin-bottom: 20px;}

.left-30 {width: 29%; float: left;
			.padding { padding: 2% 1% 0% 3%; }

// Для телефонов до 480
	@include respond-to(handhelds) { float: none; width: 100%; };
	// Для телефонов с широким экраном до 767
	@include respond-to(wide-handhelds) { float: none; width: 100%; };
	// Для планшетов до 979
	@include respond-to(tablets) {  };
	// Для экранов от 980
	@include respond-to(display) {  };
		}


.right-70 {width: 69%; float: right;
.padding { padding: 2% 0% 0% 1%;}
			// Для телефонов до 480
			@include respond-to(handhelds) { float: none; width: 100%; };
			// Для телефонов с широким экраном до 767
			@include respond-to(wide-handhelds) { float: none; width: 100%; };


} /* .right-content */

.center-100 { 

			.padding { padding: 20px; 

			@include respond-to(handhelds) { padding: 10px;  };
			@include respond-to(wide-handhelds) { padding:10px; };


			}
} /*  .center-100  */ 


.left-content {width: 29%; float: left;
			.padding { padding: 2% 1% 0% 3%; }

// Для телефонов до 480
	@include respond-to(handhelds) { float: none; width: 100%; };
	// Для телефонов с широким экраном до 767
	@include respond-to(wide-handhelds) { float: none; width: 100%; };
	// Для планшетов до 979
	@include respond-to(tablets) {  };
	// Для экранов от 980
	@include respond-to(display) {  };
		}


.right-content {width: 69%; float: right;
.padding { padding: 2% 0% 0% 1%;}
			// Для телефонов до 480
			@include respond-to(handhelds) { float: none; width: 100%; };
			// Для телефонов с широким экраном до 767
			@include respond-to(wide-handhelds) { float: none; width: 100%; };


p {line-height: 130%;}

} /* .right-content */

.images-100 {max-width:100%;}

.title {text-align: center; margin-bottom: 30px;}

.title h1 {font-size: 24px; color: #000;  margin-bottom: 20px;


hr {height: 1px; border: none; color: #717171; background: #717171; margin: 0 auto; width:70%; }
i {display:block; height:30px;}
b {display:inline-block;}

 }

.title h2 {font-size: 20px; color: #000;  margin-bottom: 20px;}

.title-main {background: #07d357; margin-bottom: -40px;  }
.title-main h2 {color: #fff; padding: 10px 0px 5px 0px;}



/* .title > hr { text-align: center;
height: 1px;
border: none;
color: #717171;
background: #717171;
margin: 0 auto;
width:120px;
} */



.block-text p { color:#989898; font-size: 14px; line-height: 130%; margin-bottom: 10px;

// Для телефонов до 480
	@include respond-to(handhelds) { font-size: 13px; };
	// Для телефонов с широким экраном до 767
	@include respond-to(wide-handhelds) { font-size: 13px; };

}

.center-text p {color:#000; font-size: 14px; line-height: 130%; margin-bottom: 10px;

// Для телефонов до 480
	@include respond-to(handhelds) { font-size: 13px; };
	// Для телефонов с широким экраном до 767
	@include respond-to(wide-handhelds) { font-size: 13px; };

}

.center-text h1 {font-size: 21px; margin-bottom: 10px;}
.center-text h2 {font-size: 18px; margin-bottom: 8px;}
.center-text h3 {font-size: 16px; margin-bottom: 6px;}


#bottom-link { text-align: center; margin:40px 0px;

a {display: inline-block;
margin: 0px 20px 20px 20px;
padding: 30px 40px;
border: 1px solid #d8b161;
color: #d8b161;
font-size: 30px;

&:hover {border: 1px solid #989898; color: #989898;}

}

} /* #bottom-link */


.font-null {font-size:0;}


span.skype_c2c_container img.skype_c2c_logo_img {display: none!important;}



.main-block {position:absolute; bottom:200px; left:0px; width:100%; z-index:90;
			// Для телефонов
	@include respond-to(handhelds) {bottom:150px;  };
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { bottom:150px;  };
	// Для маленьких планшетов
	@include respond-to(small-tablets) { bottom:150px; };


}
.main-block-podblock {width:580px;  background:rgba(0, 0, 0, 0.6); margin:100px auto 0px auto; border-radius: 20px;


			// Для телефонов
	@include respond-to(handhelds) { width:90%; margin:0 5%; };
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) {  width:90%; margin:0 5%; };
	// Для маленьких планшетов
	@include respond-to(small-tablets) {  width:90%; margin:0 5%; };

.padding {padding: 0px 20px;} 

}

.main-block-logo {margin-top: 40px; text-align: center;}
.main-block-logo img {max-width:100%;}

.main-block-text {margin-top:40px; color: #fff; text-align: center;
p {line-height:130%;}
}

.main-block-menu {margin-top:40px;  text-align: justify;
        /* Лекарство для IE6-7*/
        text-justify: newspaper;}

.main-block-menu a { display: inline-block; line-height: 130%; margin: 0 15px 0 15px; padding: 10px 20px; color:#d8b161; border: 1px solid #d8b161; font-size: 18px;

			// Для телефонов
	@include respond-to(handhelds) {display: block; width:70%; margin:0 5% 20px 5%; };
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { display: block; width:50%; margin:0 5% 20px 5%; };
	// Для маленьких планшетов
	@include respond-to(small-tablets) { display: block; width:40%; margin:0 5% 20px 5%; };

  }

.main-block-menu a:hover {color:#fff;  border: 1px solid #fff; }
  
.main-block-menu a.hidden {
                width: 100%;
                height: 0;
                visibility: hidden;
					}



#breadcrumb { text-align:center; color:#989898; margin:40px 0px; 
	.padding { padding:0px 20px;


				// < 479
				@include respond-to(handhelds) { padding:0px 10px;
 }
				// 480 > 599
				@include respond-to(wide-handhelds) { padding:0px 10px;
 }



	}

	.breadcrumb-center {display:inline-block; line-height:130%;}
	a {color:#989898; text-decoration:underline;
		&:hover {text-decoration:none;}
	}

}



