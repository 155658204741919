@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }

/* Код для подключения шрифта в /css/stylename.css */
@font-face {
  font-family: "RobotoRegular";
  src: url("../fonts/RobotoRegular/RobotoRegular.eot");
  src: url("../fonts/RobotoRegular/RobotoRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/RobotoRegular.woff") format("woff"), url("../fonts/RobotoRegular/RobotoRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "RobotoLight";
  src: url("../fonts/RobotoLight/RobotoLight.eot");
  src: url("../fonts/RobotoLight/RobotoLight.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/RobotoLight.woff") format("woff"), url("../fonts/RobotoLight/RobotoLight.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "RobotoMedium";
  src: url("../fonts/RobotoMedium/RobotoMedium.eot");
  src: url("../fonts/RobotoMedium/RobotoMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMedium/RobotoMedium.woff") format("woff"), url("../fonts/RobotoMedium/RobotoMedium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "RobotoBold";
  src: url("../fonts/RobotoBold/RobotoBold.eot");
  src: url("../fonts/RobotoBold/RobotoBold.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/RobotoBold.woff") format("woff"), url("../fonts/RobotoBold/RobotoBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

body {
  font-family: "RobotoRegular", sans-serif;
  background: #efefef; }

.clear {
  clear: both; }

.none {
  line-height: 0; }

#header-top {
  background: #00b74a; }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #header-top {
      min-height: 158px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #header-top {
      min-height: 158px; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #header-top {
      min-height: 135px; } }
  @media only screen and (min-width: 980px) {
    #header-top {
      min-height: 135px; } }
  @media only screen and (min-width: 1200px) {
    #header-top {
      min-height: 102px; } }
  #header-top .pod-block {
    min-height: 102px; }
  #header-top .header-logo {
    float: left;
    max-width: 100%;
    margin: 5px 0px 5px 40px; }
    @media only screen and (max-width: 479px) {
      #header-top .header-logo {
        float: none;
        margin: 0px;
        padding: 20px 0px 20px 20px;
        text-align: center; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #header-top .header-logo {
        float: left;
        width: calc(40% - 20px);
        margin: 0px;
        padding: 20px 0px 20px 20px;
        text-align: center; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #header-top .header-logo {
        float: left;
        width: calc(50% - 20px);
        margin: 0px;
        padding: 20px 0px 20px 20px;
        text-align: center; } }
    #header-top .header-logo img {
      max-width: 100%;
      max-height: 90px; }
  #header-top .menu-top {
    position: absolute;
    top: 0px;
    left: 40%; }
    @media only screen and (max-width: 479px) {
      #header-top .menu-top {
        float: none;
        position: relative;
        top: auto;
        left: auto;
        width: calc(100% - 20px);
        text-align: center;
        padding: 0px 10px 0px 10px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #header-top .menu-top {
        float: none;
        position: relative;
        top: auto;
        left: auto;
        width: calc(100% - 20px);
        text-align: center;
        padding: 0px 10px 0px 10px; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #header-top .menu-top {
        float: none;
        position: relative;
        top: auto;
        left: auto;
        width: calc(100% - 40px);
        text-align: center;
        padding: 0px 20px 0px 20px; } }
    #header-top .menu-top li {
      display: inline-block;
      margin: 0px 20px 0px 0px;
      border-top: 2px solid #00b74a; }
      #header-top .menu-top li.active, #header-top .menu-top li:hover {
        border-top: 2px solid #fff; }
      #header-top .menu-top li a {
        font-family: "RobotoLight";
        line-height: 130%;
        font-size: 16px;
        font-weight: normal;
        color: #fff; }
  #header-top .header-info {
    float: right;
    min-width: 280px;
    text-align: right;
    padding: 5px 10px 0px 10px;
    font-family: "RobotoLight";
    line-height: 130%;
    font-size: 14px;
    font-weight: normal;
    color: #fff; }
    @media only screen and (max-width: 479px) {
      #header-top .header-info {
        float: none;
        position: relative;
        top: auto;
        left: auto;
        width: calc(100% - 20px);
        text-align: center;
        padding: 10px 10px 10px 10px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #header-top .header-info {
        margin-top: 20px;
        float: right;
        position: relative;
        top: auto;
        left: auto;
        width: calc(60% - 20px);
        text-align: center;
        padding: 10px 10px 10px 10px;
        min-width: auto;
        background: none; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #header-top .header-info {
        margin-top: 20px;
        float: right;
        position: relative;
        top: auto;
        left: auto;
        width: calc(50% - 40px);
        text-align: center;
        padding: 10px 10px 10px 10px;
        min-width: auto;
        background: none; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #header-top .header-info {
        margin-top: 30px;
        background: none; } }
    @media only screen and (min-width: 980px) {
      #header-top .header-info {
        margin-top: 30px;
        background: none; } }
    @media only screen and (min-width: 1200px) {
      #header-top .header-info {
        margin-top: 0px;
        background: rgba(0, 0, 0, 0.1); } }
    #header-top .header-info img, #header-top .header-info span {
      display: inline-block;
      vertical-align: middle; }
    #header-top .header-info p {
      margin: 0px 0px 5px 0px; }
    #header-top .header-info .header-info-tel:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
    #header-top .header-info .header-info-tel-left {
      float: left;
      width: 50%; }
    #header-top .header-info .header-info-tel-right {
      float: right;
      width: 50%; }

#header-menu {
  min-height: 52px;
  background: #309559; }

@media only screen and (min-width: 980px) {
  .menu-left {
    width: 70%;
    float: left; } }

.menu-right {
  float: right;
  width: 30%;
  text-align: right; }
  @media only screen and (max-width: 479px) {
    .menu-right {
      float: none;
      width: calc(100% - 20px);
      background: #309559;
      padding: 5px 10px;
      text-align: center; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .menu-right {
      float: none;
      width: calc(100% - 20px);
      background: #309559;
      padding: 5px 10px;
      text-align: center; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .menu-right {
      float: none;
      width: 100%;
      background: #309559;
      padding: 5px 10px;
      text-align: center; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    .menu-right {
      float: none;
      width: 100%;
      background: #309559;
      padding: 5px 10px;
      text-align: center; } }
  .menu-right ul {
    margin-top: 10px; }
  .menu-right ul li {
    display: inline-block;
    font-size: 12px;
    padding: 5px 5px; }
    .menu-right ul li.active {
      background: #257a47; }
    .menu-right ul li a {
      color: #fff;
      padding: 5px; }
      .menu-right ul li a img {
        margin-right: 5px; }
      .menu-right ul li a img, .menu-right ul li a span {
        display: inline-block;
        vertical-align: middle; }

#secrch-block {
  text-align: left;
  padding: 10px 20px;
  display: none;
  width: 50%; }
  @media only screen and (max-width: 479px) {
    #secrch-block {
      width: calc(100% - 40px); } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #secrch-block {
      margin: 0px auto; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #secrch-block {
      margin: 0px auto; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #secrch-block {
      margin: 0px auto; } }
  #secrch-block.active {
    display: block; }

@media only screen and (min-width: 980px) {
  #search {
    margin-top: 10px; } }

.search {
  position: absolute;
  top: 76px;
  right: 10px;
  background: #fff;
  z-index: 101;
  padding: 5px 10px;
  box-shadow: 0px 0px 5px 2px #7B7878; }

#secrch-block {
  position: relative; }

#search {
  width: 100%;
  font-size: 14px;
  border: 1px solid gainsboro;
  box-sizing: border-box;
  padding: 3px; }

#search:hover {
  border: 1px solid #707070; }

#search:focus {
  border: 1px solid #707070; }

#search-result {
  position: absolute;
  left: 50%;
  top: 36px;
  width: 50%;
  margin-left: -25%;
  box-sizing: border-box; }
  @media only screen and (max-width: 479px) {
    #search-result {
      left: 20px;
      margin-left: 0;
      width: calc(100% - 40px); } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #search-result {
      left: 20px;
      margin-left: 0;
      width: calc(100% - 40px); } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #search-result {
      top: 38px;
      margin-left: 0;
      left: 20px;
      width: calc(100% - 40px); } }
  @media only screen and (min-width: 980px) {
    #search-result {
      top: 86px;
      margin-left: 0;
      left: 20px;
      width: calc(100% - 40px); } }
  @media only screen and (min-width: 1200px) {
    #search-result {
      margin-left: 0;
      left: 20px;
      width: calc(100% - 40px); } }

.search-inner {
  background-color: #fff;
  z-index: 107;
  position: relative;
  transition: all 0.2s linear;
  border-top: none;
  box-shadow: 3px 5px 8px rgba(50, 50, 50, 0.6); }

.search-inner a {
  display: block;
  padding: 7px 10px;
  text-decoration: none;
  font-size: 14px;
  color: #000;
  line-height: 130%; }
  .search-inner a:hover {
    background: #309559;
    background-image: -owg(linear-gradient(to bottom, #36a865, #2a824d));
    background-image: -webkit(linear-gradient(to bottom, #36a865, #2a824d));
    background-image: -moz(linear-gradient(to bottom, #36a865, #2a824d));
    background-image: -o(linear-gradient(to bottom, #36a865, #2a824d));
    background-image: linear-gradient(to bottom, #36a865, #2a824d);
    color: #fff; }

a.template_napravlenie {
  color: #2b844e;
  font-size: 16px; }

.search-inner-OFF a:hover {
  background-color: #eee; }

.search-inner a:after {
  content: "\2192";
  float: right; }

.header-baner {
  background: url("../img/baner-center.jpg") center;
  min-height: 220px;
  position: relative; }
  .header-baner .nadpis-center {
    width: 360px;
    position: absolute;
    left: calc(50% - 180px);
    bottom: 0px;
    padding: 16px 32px;
    background: #36d474;
    font-family: "RobotoBold";
    line-height: 130%;
    font-size: 16px;
    font-weight: normal;
    color: #fff;
    text-transform: uppercase; }
    @media only screen and (max-width: 479px) {
      .header-baner .nadpis-center {
        width: calc(90% - 32px);
        padding: 8px 16px;
        font-size: 14px;
        left: 5%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      .header-baner .nadpis-center {
        width: calc(80% - 32px);
        padding: 8px 16px;
        left: 10%;
        font-size: 14px; } }

#header.header-fon {
  background: url("../img/header.jpg") bottom center; }

#header {
  min-height: 370px; }
  @media only screen and (max-width: 479px) {
    #header {
      height: 220px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #header {
      height: 320px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #header {
      height: 320px; } }
  #header .pod-block {
    margin: 0 auto;
    position: relative;
    box-sizing: content-box; }
    @media only screen and (max-width: 479px) {
      #header .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #header .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #header .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #header .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 980px) {
      #header .pod-block {
        max-width: 1200px; } }
    #header .pod-block .padding {
      padding: 0px 20px; }
    #header .pod-block .header-logo {
      float: left;
      margin: 10px 0px; }
      @media only screen and (max-width: 479px) {
        #header .pod-block .header-logo {
          width: 160px; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #header .pod-block .header-logo {
          width: 220px; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #header .pod-block .header-logo {
          width: 220px; } }
      @media only screen and (min-width: 768px) and (max-width: 979px) {
        #header .pod-block .header-logo {
          width: 220px; } }
    #header .pod-block .header-logo img {
      width: 100%; }

/* #header*/
#footer {
  background: #60af5e; }
  #footer .pod-block {
    min-height: 234px;
    margin: 0 auto;
    position: relative;
    box-sizing: content-box; }
    #footer .pod-block .padding {
      padding: 16px 10px 0px 16px; }
    #footer .pod-block.footer-fon {
      background: url("../img/footer.jpg") bottom center; }
    @media only screen and (max-width: 479px) {
      #footer .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #footer .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #footer .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #footer .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 980px) {
      #footer .pod-block {
        max-width: 1200px; } }
  #footer .footer-top-line {
    font-size: 0px; }
  #footer .footer-block {
    display: inline-block;
    vertical-align: top;
    width: 23%;
    margin-right: 2%;
    line-height: 130%;
    font-size: 18px;
    color: #fff; }
    #footer .footer-block h3 {
      margin-bottom: 8px; }
    #footer .footer-block a {
      color: #fff; }
    @media only screen and (max-width: 479px) {
      #footer .footer-block {
        width: 96%;
        margin: 0 2% 16px 2%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #footer .footer-block {
        width: 44%;
        margin: 0 3% 32px 3%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #footer .footer-block {
        width: 44%;
        margin: 0 3% 32px 3%; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #footer .footer-block {
        font-size: 16px; } }

#h1-text .padding {
  padding: 20px 20px 50px 20px; }

/* #about */
#catalog-mest .padding {
  padding-bottom: 20px; }

#catalog-mest ul {
  font-size: 0;
  margin-bottom: 40px;
  font-size: 0px; }

#catalog-mest ul li {
  display: inline-block;
  width: calc(20% - 22px);
  margin: 10px;
  position: relative;
  vertical-align: top;
  background: #fff;
  border: 1px solid #313131; }
  @media only screen and (max-width: 479px) {
    #catalog-mest ul li {
      width: calc(100% - 22px); } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #catalog-mest ul li {
      width: calc(50% - 22px); } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #catalog-mest ul li {
      width: calc(33.3% - 22px); } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #catalog-mest ul li {
      width: calc(25% - 22px); } }
  #catalog-mest ul li img {
    max-width: 100%; }
  #catalog-mest ul li h3 {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(100% - 20px);
    padding: 8px 10px;
    background: rgba(88, 207, 136, 0.8);
    font-size: 14px;
    color: #fff;
    text-align: center; }

#catalog .padding {
  padding-bottom: 20px; }

#catalog ul {
  font-size: 0;
  margin-bottom: 40px;
  font-size: 0px; }

#catalog ul li {
  display: inline-block;
  width: calc(25% - 22px);
  margin: 10px;
  position: relative;
  vertical-align: top;
  background: #fff;
  border: 1px solid #313131; }
  @media only screen and (max-width: 479px) {
    #catalog ul li {
      width: calc(100% - 22px); } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #catalog ul li {
      width: calc(50% - 22px); } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #catalog ul li {
      width: calc(50% - 22px); } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #catalog ul li {
      width: calc(33.3% - 22px); } }
  #catalog ul li .skidki-line {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 100px;
    height: 100px;
    background: url("../img/skidki-orange-text.png") left center no-repeat;
    background-size: contain; }
  #catalog ul li .price-line {
    background: #60af5e;
    line-height: 130%;
    width: 80%;
    z-index: 2;
    position: absolute;
    bottom: -10px;
    margin: 0px 10%;
    font-size: 14px;
    color: #fff; }
    #catalog ul li .price-line:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
    #catalog ul li .price-line.price-line-center {
      width: 60%;
      margin: 0px 20%;
      text-align: center; }
      #catalog ul li .price-line.price-line-center .price {
        float: none;
        padding: 5px; }
    #catalog ul li .price-line .price-old {
      float: left;
      padding: 5px;
      text-decoration: line-through; }
    #catalog ul li .price-line .price {
      float: right;
      padding: 5px; }
  #catalog ul li a.cart-images {
    position: relative;
    display: block; }
  #catalog ul li a.cart-title {
    padding: 10px 10px 0px 10px;
    display: block; }
  #catalog ul li h4 {
    font-family: "RobotoBold";
    line-height: 130%;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    color: #000;
    text-align: center;
    padding: 15px 0px 15px 0px; }
    @media only screen and (max-width: 479px) {
      #catalog ul li h4 {
        font-size: 14px; } }
  #catalog ul li .info-line {
    font-size: 0px;
    padding: 0px 0px 10px 0px; }
  #catalog ul li .info-line-center {
    text-align: center; }
  #catalog ul li .info, #catalog ul li .date, #catalog ul li .info-icon, #catalog ul li .date-icon {
    font-size: 14px;
    line-height: 130%;
    display: inline-block;
    vertical-align: top; }
  #catalog ul li .info-icon {
    background: url("../img/place-icon.png") left center no-repeat;
    background-size: contain;
    width: 20px;
    height: 26px;
    margin-left: 10px; }
  #catalog ul li .date-icon {
    background: url("../img/date-icon.png") left center no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px; }
  #catalog ul li .info {
    padding-left: 10px;
    width: calc(65% - 45px);
    margin-right: 5px; }
    #catalog ul li .info a {
      color: #60af5e; }
      #catalog ul li .info a:hover {
        text-decoration: underline; }
  #catalog ul li .date {
    padding: 0px 10px 0px 5px;
    width: calc(35% - 35px); }

#catalog ul li a:hover h4 {
  color: #60af5e; }

#catalog ul li a img {
  max-width: 100%; }

/*  #category */
#bottom-text .pod-block {
  background: #fff; }

#bottom-text .padding {
  padding: 10px 20px 30px 20px; }
  @media only screen and (max-width: 479px) {
    #bottom-text .padding {
      padding: 10px 10px 20px 10px; } }

.text-col-line-3 {
  padding: 0px 10px;
  width: calc(100% - 20px);
  /*указываем общую ширину блока с колонками*/
  text-align: justify;
  /*выравнивание текста внутри колонок*/
  column-count: 3;
  /*количество колонок, на которое хотим разбить текст*/
  -moz-column-count: 3;
  /*для мозилы*/
  -webkit-column-count: 3;
  /*для webkit браузеров*/
  column-gap: 20px;
  /*отступ между колонками*/
  -moz-column-gap: 20px;
  -webkit-column-gap: 20px;
  /* column-rule: 1px solid #000; если требуется по дизайну, разделяем колонки линией
-moz-column-rule: 1px solid #000;
-webkit-column-rule: 1px solid #000;*/ }
  @media only screen and (max-width: 479px) {
    .text-col-line-3 {
      column-count: 1;
      margin: 0 0 40px 0; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .text-col-line-3 {
      column-count: 1; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .text-col-line-3 {
      column-count: 2; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    .text-col-line-3 {
      column-count: 2; } }
  .text-col-line-3 img {
    max-width: 100%;
    margin-bottom: 18px; }
  .text-col-line-3 h2 {
    font-family: "RobotoBold";
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 18px;
    line-height: 130%; }
    .text-col-line-3 h2 b {
      font-weight: normal; }
  .text-col-line-3 p {
    font-family: "RobotoRegular";
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 16px;
    line-height: 130%; }

.text-col-line-1 {
  padding: 0px 10px;
  width: calc(100% - 20px);
  /*указываем общую ширину блока с колонками*/
  text-align: justify;
  /*выравнивание текста внутри колонок*/ }
  .text-col-line-1 table {
    width: 100%;
    border: 1px solid #ddd;
    border-right-width: 0;
    border-bottom-width: 0;
    border-spacing: 0;
    border-collapse: collapse; }
    .text-col-line-1 table th, .text-col-line-1 table td {
      font-size: 13px;
      padding: 0.5em 10px;
      border: 1px solid #ddd;
      border-left-width: 0;
      border-top-width: 0;
      vertical-align: middle; }
      .text-col-line-1 table th b, .text-col-line-1 table td b {
        font-weight: normal; }
      .text-col-line-1 table th strong, .text-col-line-1 table td strong {
        font-weight: normal; }
  .text-col-line-1 img {
    max-width: 100%;
    margin-bottom: 18px; }
  .text-col-line-1 h1 {
    font-family: "RobotoBold";
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
    line-height: 130%; }
    .text-col-line-1 h1 b {
      font-weight: normal; }
  .text-col-line-1 h2 {
    font-family: "RobotoBold";
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 18px;
    line-height: 130%; }
    .text-col-line-1 h2 b {
      font-weight: normal; }
  .text-col-line-1 p {
    font-family: "RobotoRegular";
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 16px;
    line-height: 130%; }
  .text-col-line-1 a {
    color: #FF9000;
    font-size: 14px; }
    .text-col-line-1 a b {
      font-weight: normal; }
    .text-col-line-1 a strong {
      font-weight: normal; }

#h1-text .padding {
  padding: 20px 20px 50px 20px; }

/* #about */
.fotorama-sm {
  display: none; }

@media only screen and (max-width: 479px) {
  .fotorama-big {
    display: none; }
  .fotorama-sm {
    display: block; } }

@media only screen and (min-width: 480px) and (max-width: 599px) {
  .fotorama-big {
    display: none; }
  .fotorama-sm {
    display: block; } }

.cart-block:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.cart-block > div > .padding {
  padding: 20px 0px; }

.cart-block .top-header h1 {
  font-family: "RobotoBold";
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 18px;
  margin-top: 10px;
  line-height: 130%; }

.cart-block .tour-info {
  margin-bottom: 5px;
  background: #fafafa;
  padding: 10px 10px 5px 10px; }
  .cart-block .tour-info ul li {
    line-height: 130%;
    font-size: 16px;
    margin-bottom: 5px; }
    .cart-block .tour-info ul li .icon {
      position: relative;
      bottom: -1px;
      width: 16px;
      height: 16px;
      float: left;
      margin: 0 5px -5px 0;
      background: #FF9000 url(../img/colored_icons.png) 0 0 no-repeat; }
      .cart-block .tour-info ul li .icon.icon-naprav {
        background-position: -6px -34px; }
      .cart-block .tour-info ul li .icon.icon-days {
        background-position: -6px -5px; }
      .cart-block .tour-info ul li .icon.icon-otprav {
        background-position: -6px -141px; }
      .cart-block .tour-info ul li .icon.icon-prib {
        background-position: -6px -167px; }
      .cart-block .tour-info ul li .icon.icon-cena {
        background-position: -7px -64px; }
    .cart-block .tour-info ul li a {
      color: #00af58; }
      .cart-block .tour-info ul li a:hover {
        text-decoration: underline; }

.cart-block .dop-info {
  margin-bottom: 5px; }
  .cart-block .dop-info p {
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 5px; }

.cart-block h3.title-share {
  margin: 20px 0px 10px 0px; }

.cart-block .share42init {
  margin-bottom: 20px; }

.cart-block .line-btn {
  font-size: 0px; }
  .cart-block .line-btn .btn {
    width: 31%;
    display: inline-block;
    margin-right: 2%; }
  .cart-block .line-btn .btn-bron a, .cart-block .line-btn .btn-oplata a, .cart-block .line-btn .btn-vopros a {
    display: block;
    font-size: 13px;
    padding: 10px 5px;
    background: #00af58;
    color: #fff;
    text-transform: uppercase;
    line-height: 130%;
    text-align: center; }

.cart-block .link-tabs ul {
  font-size: 0;
  margin-left: 1px; }

.cart-block .link-tabs ul li {
  display: inline-block;
  font-size: 14px;
  margin-right: 2px; }
  .cart-block .link-tabs ul li a {
    padding: 10px 20px;
    color: #000;
    border-top: 2px solid #07d357;
    display: block; }
  .cart-block .link-tabs ul li.active {
    margin-bottom: -1px; }
    .cart-block .link-tabs ul li.active a {
      border-top: none;
      border-bottom: 1px solid #fff;
      background: #fff; }

.cart-block .tab-open {
  border: 1px solid #E5E5E5;
  background: #fff;
  margin-bottom: 20px; }
  .cart-block .tab-open > .padding {
    padding: 20px; }

.cart-block .gallery .owl-item {
  margin: 0px 1px; }

.cart-block .gallery ul.cart-gallery {
  font-size: 0;
  margin-bottom: 40px; }

.cart-block .gallery ul.cart-gallery li {
  display: inline-block;
  margin: 1px;
  width: calc(25% - 8px); }
  @media only screen and (max-width: 479px) {
    .cart-block .gallery ul.cart-gallery li {
      width: calc(100% - 2px); } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .cart-block .gallery ul.cart-gallery li {
      width: calc(50% - 2px); } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .cart-block .gallery ul.cart-gallery li {
      width: calc(50% - 2px); } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    .cart-block .gallery ul.cart-gallery li {
      width: calc(50% - 2px); } }
  @media only screen and (min-width: 980px) {
    .cart-block .gallery ul.cart-gallery li {
      width: calc(33.3% - 6px); } }
  @media only screen and (min-width: 1200px) {
    .cart-block .gallery ul.cart-gallery li {
      width: calc(33.3% - 6px); } }

.cart-block .gallery ul.cart-gallery li img {
  max-width: 100%; }

.cart-block .gallery ul.cart-owl li img {
  max-width: 100%; }

.cart-block .gallery .show-all-gallery {
  text-align: center;
  margin: 20px 0px; }
  .cart-block .gallery .show-all-gallery.active {
    display: none; }

.cart-block .gallery .show-all-gallery a {
  color: #FF9000;
  text-decoration: underline;
  padding: 10px 20px; }
  .cart-block .gallery .show-all-gallery a:hover {
    text-decoration: none; }

.cart-block .gallery .show-all-gallery.active {
  display: none; }

.cart-block .gallery ul.cart-gallery li a, .cart-block .gallery ul.cart-owl li a {
  position: relative;
  display: block; }

.cart-block .gallery ul.cart-gallery li a span, .cart-block .gallery ul.cart-owl li a span {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../img/play-button.png) center center;
  background-size: cover;
  transition: all 0.3s linear; }
  .cart-block .gallery ul.cart-gallery li a span:hover, .cart-block .gallery ul.cart-owl li a span:hover {
    background: url(../img/play-button.png) center center;
    background-size: cover;
    transition: all 0.3s linear; }

.cart-block .gallery .cart-gallery {
  display: none; }

.cart-block .gallery .cart-gallery.open {
  display: block; }

.cart-block .gallery .cart-owl.close {
  display: none !important; }

/*  #cart */
#bottom-text .pod-block {
  background: #fff; }

#bottom-text .padding {
  padding: 10px 20px 30px 20px; }
  @media only screen and (max-width: 479px) {
    #bottom-text .padding {
      padding: 10px 10px 20px 10px; } }

.tur-text {
  background: #fff; }
  .tur-text p {
    font-size: 14px;
    margin: 0 0 1em 0;
    line-height: 1.5em;
    padding: 0; }
  .tur-text a {
    color: #FF9000;
    text-decoration: underline; }
    .tur-text a:hover {
      text-decoration: none; }
  .tur-text h1 {
    font-family: "RobotoBold";
    font-weight: normal;
    font-size: 24px;
    margin-bottom: 0.6em;
    line-height: 1em;
    color: #444; }
  .tur-text h2 {
    font-family: "RobotoBold";
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 0.6em;
    line-height: 1em;
    color: #444; }
  .tur-text h3 {
    font-family: "RobotoBold";
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 0.7em;
    line-height: 1em;
    color: #444; }
  .tur-text table {
    width: 100%;
    border: 1px solid #ddd;
    border-right-width: 0;
    border-bottom-width: 0;
    border-spacing: 0;
    border-collapse: collapse; }
    .tur-text table th, .tur-text table td {
      font-size: 13px;
      padding: 0.5em 10px;
      border: 1px solid #ddd;
      border-left-width: 0;
      border-top-width: 0; }

.tur-text-center h1 {
  font-size: 1.9em;
  margin-bottom: 0.5em; }

.tab-text p {
  font-size: 13px; }

ul.tab-gallery {
  font-size: 0;
  margin-bottom: 40px; }
  ul.tab-gallery li {
    display: inline-block;
    margin: 1px;
    width: calc(25% - 8px); }
    @media only screen and (max-width: 479px) {
      ul.tab-gallery li {
        width: calc(100% - 2px); } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      ul.tab-gallery li {
        width: calc(50% - 2px); } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      ul.tab-gallery li {
        width: calc(50% - 2px); } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      ul.tab-gallery li {
        width: calc(50% - 2px); } }
    @media only screen and (min-width: 980px) {
      ul.tab-gallery li {
        width: calc(33.3% - 6px); } }
    @media only screen and (min-width: 1200px) {
      ul.tab-gallery li {
        width: calc(33.3% - 6px); } }
    ul.tab-gallery li img {
      max-width: 100%; }

.tabs {
  margin: 0 0 30px; }

.tabs__caption {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  position: relative;
  margin: -1px 0 0 -1px; }

.tabs__caption li:last-child:before {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: -2px;
  z-index: -1;
  height: 5px;
  background: #7DCA86; }

.tabs__caption:after {
  content: '';
  display: table;
  clear: both; }

.tabs__caption li {
  padding: 9px 15px;
  margin: 1px 0 0 1px;
  background: #61AF6A;
  color: #FFF;
  position: relative;
  border: solid #61AF6A;
  border-width: 2px 2px 0;
  border-radius: 5px 5px 0 0;
  text-align: center; }
  .tabs__caption li a {
    color: #FFF; }

.tabs__caption li:not(.active) {
  cursor: pointer; }

.tabs__caption li:not(.active):hover {
  background: #7DCA86;
  border-color: #7DCA86; }

.tabs__caption .active {
  background: #FFF;
  color: #000;
  border-color: #7DCA86; }
  .tabs__caption .active a {
    color: #000; }

.tabs__caption .active:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 5px;
  background: #FFF; }

.tabs__content {
  display: none;
  background: #FFF;
  border: 2px solid #7DCA86;
  border-radius: 0 5px 5px 5px;
  padding: 7px 15px; }

.tabs__content.active {
  display: block; }

.vertical .tabs__caption {
  float: left;
  display: block; }

.vertical .tabs__caption li {
  float: none;
  border-width: 2px 0 2px 2px;
  border-radius: 5px 0 0 5px; }

.vertical .tabs__caption li:last-child:before {
  display: none; }

.vertical .tabs__caption .active:after {
  left: auto;
  top: 0;
  right: -2px;
  bottom: 0;
  width: 2px;
  height: auto; }

.vertical .tabs__content {
  overflow: hidden; }

@media screen and (max-width: 650px) {
  .tabs__caption li {
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto; }
  .vertical .tabs__caption {
    float: none;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .vertical .tabs__caption li {
    border-width: 2px 2px 0;
    border-radius: 5px 5px 0 0; }
  .vertical .tabs__caption li:last-child:before {
    display: block; }
  .vertical .tabs__caption .active:after {
    top: auto;
    bottom: -5px;
    left: 0;
    right: 0;
    width: auto;
    height: 5px;
    background: #FFF; } }

.big-tab-img {
  margin: 10px 0px 20px 0px; }
  .big-tab-img img {
    max-width: 100%; }

#stati .padding {
  padding: 20px 20px 0px 20px; }

#stati .stati-block {
  width: 100%;
  margin: 0 0 60px 0; }
  #stati .stati-block:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  @media only screen and (max-width: 479px) {
    #stati .stati-block {
      margin-bottom: 16%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #stati .stati-block {
      display: inline-block;
      width: 44%;
      margin: 0 4% 8% 0; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #stati .stati-block {
      display: inline-block;
      width: 44%;
      margin: 0 4% 8% 0; } }
  #stati .stati-block .stati-block-left {
    float: left;
    width: 30%;
    margin: 0 5% 0 0; }
    @media only screen and (max-width: 479px) {
      #stati .stati-block .stati-block-left {
        float: none;
        width: 100%;
        margin: 0 0 20px 0; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #stati .stati-block .stati-block-left {
        float: none;
        width: 100%;
        margin: 0 0 20px 0; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #stati .stati-block .stati-block-left {
        float: none;
        width: 100%;
        margin: 0 0 20px 0; } }
    #stati .stati-block .stati-block-left img {
      max-width: 100%; }
  #stati .stati-block .stati-block-right {
    float: right;
    width: 65%; }
    @media only screen and (max-width: 479px) {
      #stati .stati-block .stati-block-right {
        float: none;
        width: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #stati .stati-block .stati-block-right {
        float: none;
        width: 100%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #stati .stati-block .stati-block-right {
        float: none;
        width: 100%; } }
    #stati .stati-block .stati-block-right h2 {
      margin-bottom: 0.8em; }
    #stati .stati-block .stati-block-right p {
      margin-bottom: 2em;
      line-height: 130%; }
      @media only screen and (max-width: 479px) {
        #stati .stati-block .stati-block-right p {
          margin-bottom: 1.3em; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #stati .stati-block .stati-block-right p {
          display: none; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #stati .stati-block .stati-block-right p {
          display: none; } }
    #stati .stati-block .stati-block-right a.btn {
      background: #fa991b;
      padding: 5px;
      margin-bottom: 20px;
      color: white;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
      border: 1px solid #d37700; }

#otzuvu .padding {
  padding: 20px 20px 0px 20px; }

#otzuvu a.otzuv-btn {
  background: #fa991b;
  padding: 5px;
  display: inline-block;
  margin-bottom: 40px;
  color: white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #d37700; }

#otzuvu .otzuvu-block {
  width: 100%;
  margin: 0 0 30px 0; }
  #otzuvu .otzuvu-block:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  #otzuvu .otzuvu-block .otzuvu-block-header {
    margin-bottom: 10px; }
  #otzuvu .otzuvu-block .otzuvu-block-rate, #otzuvu .otzuvu-block .otzuvu-block-date, #otzuvu .otzuvu-block .otzuvu-block-name {
    display: inline-block;
    margin-right: 5px; }
  #otzuvu .otzuvu-block .otzuvu-block-rate {
    width: 84px;
    height: 15px;
    background: url(../img/rate-icon.png);
    background-repeat: no-repeat;
    background-position: 0px 0px; }
    #otzuvu .otzuvu-block .otzuvu-block-rate-1 {
      background-position: 0px -15px; }
    #otzuvu .otzuvu-block .otzuvu-block-rate-2 {
      background-position: 0px -30px; }
    #otzuvu .otzuvu-block .otzuvu-block-rate-3 {
      background-position: 0px -45px; }
    #otzuvu .otzuvu-block .otzuvu-block-rate-4 {
      background-position: 0px -60px; }
    #otzuvu .otzuvu-block .otzuvu-block-rate-5 {
      background-position: 0px -75px; }
  #otzuvu .otzuvu-block .otzuvu-block-date {
    font-size: 12px; }
  #otzuvu .otzuvu-block .otzuvu-block-name h3 {
    font-size: 16px; }
    #otzuvu .otzuvu-block .otzuvu-block-name h3 a {
      color: #fa991b;
      text-decoration: none; }
      #otzuvu .otzuvu-block .otzuvu-block-name h3 a:hover {
        text-decoration: underline; }
  #otzuvu .otzuvu-block .otzuvu-block-content, #otzuvu .otzuvu-block .otzuvu-block-content p {
    font-size: 14px;
    font-weight: normal;
    line-height: 120%;
    margin: 0 0 20px 0; }
  #otzuvu .otzuvu-block .gallery .owl-item {
    margin: 0px 1px; }
  #otzuvu .otzuvu-block .gallery ul.cart-gallery {
    font-size: 0;
    margin-bottom: 40px; }
  #otzuvu .otzuvu-block .gallery ul.cart-gallery li {
    display: inline-block;
    margin: 1px;
    width: calc(25% - 8px); }
    @media only screen and (max-width: 479px) {
      #otzuvu .otzuvu-block .gallery ul.cart-gallery li {
        width: calc(100% - 2px); } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #otzuvu .otzuvu-block .gallery ul.cart-gallery li {
        width: calc(50% - 2px); } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #otzuvu .otzuvu-block .gallery ul.cart-gallery li {
        width: calc(50% - 2px); } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #otzuvu .otzuvu-block .gallery ul.cart-gallery li {
        width: calc(50% - 2px); } }
    @media only screen and (min-width: 980px) {
      #otzuvu .otzuvu-block .gallery ul.cart-gallery li {
        width: calc(33.3% - 6px); } }
    @media only screen and (min-width: 1200px) {
      #otzuvu .otzuvu-block .gallery ul.cart-gallery li {
        width: calc(33.3% - 6px); } }
  #otzuvu .otzuvu-block .gallery ul.cart-gallery li img {
    max-width: 100%; }
  #otzuvu .otzuvu-block .gallery ul.cart-owl li img {
    max-width: 100%; }
  #otzuvu .otzuvu-block .gallery .show-all-gallery {
    text-align: center;
    margin: 20px 0px; }
    #otzuvu .otzuvu-block .gallery .show-all-gallery.active {
      display: none; }
  #otzuvu .otzuvu-block .gallery .show-all-gallery a {
    color: #FF9000;
    text-decoration: underline;
    padding: 10px 20px; }
    #otzuvu .otzuvu-block .gallery .show-all-gallery a:hover {
      text-decoration: none; }
  #otzuvu .otzuvu-block .gallery .show-all-gallery.active {
    display: none; }
  #otzuvu .otzuvu-block .gallery ul.cart-gallery li a, #otzuvu .otzuvu-block .gallery ul.cart-owl li a {
    position: relative;
    display: block; }
  #otzuvu .otzuvu-block .gallery ul.cart-gallery li a span, #otzuvu .otzuvu-block .gallery ul.cart-owl li a span {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../img/play-button.png) center center;
    background-size: cover;
    transition: all 0.3s linear; }
    #otzuvu .otzuvu-block .gallery ul.cart-gallery li a span:hover, #otzuvu .otzuvu-block .gallery ul.cart-owl li a span:hover {
      background: url(../img/play-button.png) center center;
      background-size: cover;
      transition: all 0.3s linear; }
  #otzuvu .otzuvu-block .gallery .cart-gallery {
    display: none; }
  #otzuvu .otzuvu-block .gallery .cart-gallery.open {
    display: block; }
  #otzuvu .otzuvu-block .gallery .cart-owl.close {
    display: none !important; }

/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
/* 
 * http://stackoverflow.com/questions/11649016/compact-function-in-bourbon-with-sass-not-called
 */
.sm-blue {
  background: transparent;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3); }
  .sm-blue a, .sm-blue a:hover, .sm-blue a:focus, .sm-blue a:active {
    padding: 10px 20px;
    /* make room for the toggle button (sub indicator) */
    padding-right: 58px;
    background: #309559;
    background-image: -owg(linear-gradient(to bottom, #36a865, #2a824d));
    background-image: -webkit(linear-gradient(to bottom, #36a865, #2a824d));
    background-image: -moz(linear-gradient(to bottom, #36a865, #2a824d));
    background-image: -o(linear-gradient(to bottom, #36a865, #2a824d));
    background-image: linear-gradient(to bottom, #36a865, #2a824d);
    color: #fff;
    font-family: "PT Sans Narrow", "Arial Narrow", Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3); }
  .sm-blue a.current {
    background: #07d357;
    background-image: -owg(linear-gradient(to bottom, #06ba4d, #08ec61));
    background-image: -webkit(linear-gradient(to bottom, #06ba4d, #08ec61));
    background-image: -moz(linear-gradient(to bottom, #06ba4d, #08ec61));
    background-image: -o(linear-gradient(to bottom, #06ba4d, #08ec61));
    background-image: linear-gradient(to bottom, #06ba4d, #08ec61);
    color: #fff; }
  .sm-blue a.disabled {
    color: #86d8a7; }
  .sm-blue a span.sub-arrow {
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: auto;
    right: 4px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    font: bold 16px / 34px monospace !important;
    text-align: center;
    text-shadow: none;
    background: rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px; }
  .sm-blue a.highlighted span.sub-arrow:before {
    display: block;
    content: '-'; }
  .sm-blue > li:first-child > a, .sm-blue > li:first-child > :not(ul) a {
    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    -ms-border-radius: 8px 8px 0 0;
    -o-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0; }
  .sm-blue > li:last-child > a, .sm-blue > li:last-child > *:not(ul) a, .sm-blue > li:last-child > ul,
  .sm-blue > li:last-child > ul > li:last-child > a, .sm-blue > li:last-child > ul > li:last-child > *:not(ul) a, .sm-blue > li:last-child > ul > li:last-child > ul,
  .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > a, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul,
  .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul,
  .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul {
    -webkit-border-radius: 0 0 8px 8px;
    -moz-border-radius: 0 0 8px 8px;
    -ms-border-radius: 0 0 8px 8px;
    -o-border-radius: 0 0 8px 8px;
    border-radius: 0 0 8px 8px; }
  .sm-blue > li:last-child > a.highlighted, .sm-blue > li:last-child > *:not(ul) a.highlighted,
  .sm-blue > li:last-child > ul > li:last-child > a.highlighted, .sm-blue > li:last-child > ul > li:last-child > *:not(ul) a.highlighted,
  .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted,
  .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted,
  .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
  .sm-blue ul {
    background: #fff; }
    .sm-blue ul ul {
      background: rgba(102, 102, 102, 0.1); }
    .sm-blue ul a, .sm-blue ul a:hover, .sm-blue ul a:focus, .sm-blue ul a:active {
      background: transparent;
      color: #2a824d;
      font-size: 16px;
      text-shadow: none;
      border-left: 8px solid transparent; }
    .sm-blue ul a.current {
      background: #07d357;
      background-image: -owg(linear-gradient(to bottom, #06ba4d, #08ec61));
      background-image: -webkit(linear-gradient(to bottom, #06ba4d, #08ec61));
      background-image: -moz(linear-gradient(to bottom, #06ba4d, #08ec61));
      background-image: -o(linear-gradient(to bottom, #06ba4d, #08ec61));
      background-image: linear-gradient(to bottom, #06ba4d, #08ec61);
      color: #fff; }
    .sm-blue ul a.disabled {
      color: #b3b3b3; }
    .sm-blue ul ul a,
    .sm-blue ul ul a:hover,
    .sm-blue ul ul a:focus,
    .sm-blue ul ul a:active {
      border-left: 16px solid transparent; }
    .sm-blue ul ul ul a,
    .sm-blue ul ul ul a:hover,
    .sm-blue ul ul ul a:focus,
    .sm-blue ul ul ul a:active {
      border-left: 24px solid transparent; }
    .sm-blue ul ul ul ul a,
    .sm-blue ul ul ul ul a:hover,
    .sm-blue ul ul ul ul a:focus,
    .sm-blue ul ul ul ul a:active {
      border-left: 32px solid transparent; }
    .sm-blue ul ul ul ul ul a,
    .sm-blue ul ul ul ul ul a:hover,
    .sm-blue ul ul ul ul ul a:focus,
    .sm-blue ul ul ul ul ul a:active {
      border-left: 40px solid transparent; }
    .sm-blue ul li {
      border-top: 1px solid rgba(0, 0, 0, 0.05); }
      .sm-blue ul li:first-child {
        border-top: 0; }

@media (min-width: 768px) {
  /* Switch to desktop layout
	-----------------------------------------------
	   These transform the menu tree from
	   collapsible to desktop (navbar + dropdowns)
	-----------------------------------------------*/
  /* start... (it's not recommended editing these rules) */
  .sm-blue ul {
    position: absolute; }
  .sm-blue li {
    float: left; }
  .sm-blue.sm-rtl li {
    float: right; }
  .sm-blue ul li, .sm-blue.sm-rtl ul li, .sm-blue.sm-vertical li {
    float: none; }
  .sm-blue a {
    white-space: nowrap; }
  .sm-blue ul a, .sm-blue.sm-vertical a {
    white-space: normal; }
  .sm-blue .sm-nowrap > li > a, .sm-blue .sm-nowrap > li > :not(ul) a {
    white-space: nowrap; }
  /* ...end */
  .sm-blue {
    background: #309559;
    background-image: -owg(linear-gradient(to bottom, #36a865, #2a824d));
    background-image: -webkit(linear-gradient(to bottom, #36a865, #2a824d));
    background-image: -moz(linear-gradient(to bottom, #36a865, #2a824d));
    background-image: -o(linear-gradient(to bottom, #36a865, #2a824d));
    background-image: linear-gradient(to bottom, #36a865, #2a824d);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3); }
    .sm-blue a, .sm-blue a:hover, .sm-blue a:focus, .sm-blue a:active, .sm-blue a.highlighted {
      padding: 13px 24px;
      background: #309559;
      background-image: -owg(linear-gradient(to bottom, #36a865, #2a824d));
      background-image: -webkit(linear-gradient(to bottom, #36a865, #2a824d));
      background-image: -moz(linear-gradient(to bottom, #36a865, #2a824d));
      background-image: -o(linear-gradient(to bottom, #36a865, #2a824d));
      background-image: linear-gradient(to bottom, #36a865, #2a824d);
      color: #fff; }
    .sm-blue a:hover, .sm-blue a:focus, .sm-blue a:active, .sm-blue a.highlighted {
      background: #2a824d;
      background-image: -owg(linear-gradient(to bottom, #309559, #246e42));
      background-image: -webkit(linear-gradient(to bottom, #309559, #246e42));
      background-image: -moz(linear-gradient(to bottom, #309559, #246e42));
      background-image: -o(linear-gradient(to bottom, #309559, #246e42));
      background-image: linear-gradient(to bottom, #309559, #246e42); }
    .sm-blue a.current {
      background: #07d357;
      background-image: -owg(linear-gradient(to bottom, #06ba4d, #08ec61));
      background-image: -webkit(linear-gradient(to bottom, #06ba4d, #08ec61));
      background-image: -moz(linear-gradient(to bottom, #06ba4d, #08ec61));
      background-image: -o(linear-gradient(to bottom, #06ba4d, #08ec61));
      background-image: linear-gradient(to bottom, #06ba4d, #08ec61);
      color: #fff; }
    .sm-blue a.disabled {
      background: #309559;
      background-image: -owg(linear-gradient(to bottom, #36a865, #2a824d));
      background-image: -webkit(linear-gradient(to bottom, #36a865, #2a824d));
      background-image: -moz(linear-gradient(to bottom, #36a865, #2a824d));
      background-image: -o(linear-gradient(to bottom, #36a865, #2a824d));
      background-image: linear-gradient(to bottom, #36a865, #2a824d);
      color: #86d8a7; }
    .sm-blue a span.sub-arrow {
      top: auto;
      margin-top: 0;
      bottom: 2px;
      left: 50%;
      margin-left: -5px;
      right: auto;
      width: 0;
      height: 0;
      border-width: 5px;
      border-style: solid dashed dashed dashed;
      border-color: #86d8a7 transparent transparent transparent;
      background: transparent;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0; }
    .sm-blue a.highlighted span.sub-arrow:before {
      display: none; }
    .sm-blue > li:first-child > a, .sm-blue > li:first-child > :not(ul) a {
      -webkit-border-radius: 8px 0 0 8px;
      -moz-border-radius: 8px 0 0 8px;
      -ms-border-radius: 8px 0 0 8px;
      -o-border-radius: 8px 0 0 8px;
      border-radius: 8px 0 0 8px; }
    .sm-blue > li {
      border-left: 1px solid #2a824d; }
      .sm-blue > li:first-child {
        border-left: 0; }
    .sm-blue ul {
      border: 1px solid #a8a8a8;
      padding: 7px 0;
      background: #fff;
      -webkit-border-radius: 0 0 4px 4px;
      -moz-border-radius: 0 0 4px 4px;
      -ms-border-radius: 0 0 4px 4px;
      -o-border-radius: 0 0 4px 4px;
      border-radius: 0 0 4px 4px;
      -webkit-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3);
      box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3); }
      .sm-blue ul ul {
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;
        background: #fff; }
      .sm-blue ul a, .sm-blue ul a:hover, .sm-blue ul a:focus, .sm-blue ul a:active, .sm-blue ul a.highlighted {
        border: 0 !important;
        padding: 9px 23px;
        background: transparent;
        color: #2a824d; }
      .sm-blue ul a:hover, .sm-blue ul a:focus, .sm-blue ul a:active, .sm-blue ul a.highlighted {
        background: #309559;
        background-image: -owg(linear-gradient(to bottom, #36a865, #2a824d));
        background-image: -webkit(linear-gradient(to bottom, #36a865, #2a824d));
        background-image: -moz(linear-gradient(to bottom, #36a865, #2a824d));
        background-image: -o(linear-gradient(to bottom, #36a865, #2a824d));
        background-image: linear-gradient(to bottom, #36a865, #2a824d);
        color: #fff; }
      .sm-blue ul a.current {
        background: #07d357;
        background-image: -owg(linear-gradient(to bottom, #06ba4d, #08ec61));
        background-image: -webkit(linear-gradient(to bottom, #06ba4d, #08ec61));
        background-image: -moz(linear-gradient(to bottom, #06ba4d, #08ec61));
        background-image: -o(linear-gradient(to bottom, #06ba4d, #08ec61));
        background-image: linear-gradient(to bottom, #06ba4d, #08ec61);
        color: #fff; }
      .sm-blue ul a.disabled {
        background: #fff;
        color: #b3b3b3; }
      .sm-blue ul a span.sub-arrow {
        top: 50%;
        margin-top: -5px;
        bottom: auto;
        left: auto;
        margin-left: 0;
        right: 10px;
        border-style: dashed dashed dashed solid;
        border-color: transparent transparent transparent #86d8a7; }
      .sm-blue ul li {
        border: 0; }
    .sm-blue span.scroll-up,
    .sm-blue span.scroll-down {
      position: absolute;
      display: none;
      visibility: hidden;
      overflow: hidden;
      background: #fff;
      height: 20px; }
    .sm-blue span.scroll-up-arrow, .sm-blue span.scroll-down-arrow {
      position: absolute;
      top: -2px;
      left: 50%;
      margin-left: -8px;
      width: 0;
      height: 0;
      overflow: hidden;
      border-width: 8px;
      border-style: dashed dashed solid dashed;
      border-color: transparent transparent #2a824d transparent; }
    .sm-blue span.scroll-down-arrow {
      top: 6px;
      border-style: solid dashed dashed dashed;
      border-color: #2a824d transparent transparent transparent; }
    .sm-blue.sm-rtl.sm-vertical a span.sub-arrow {
      right: auto;
      left: 10px;
      border-style: dashed solid dashed dashed;
      border-color: transparent #86d8a7 transparent transparent; }
    .sm-blue.sm-rtl > li:first-child > a, .sm-blue.sm-rtl > li:first-child > :not(ul) a {
      -webkit-border-radius: 0 8px 8px 0;
      -moz-border-radius: 0 8px 8px 0;
      -ms-border-radius: 0 8px 8px 0;
      -o-border-radius: 0 8px 8px 0;
      border-radius: 0 8px 8px 0; }
    .sm-blue.sm-rtl > li:first-child {
      border-left: 1px solid #2a824d; }
    .sm-blue.sm-rtl > li:last-child {
      border-left: 0; }
    .sm-blue.sm-rtl ul a span.sub-arrow {
      right: auto;
      left: 10px;
      border-style: dashed solid dashed dashed;
      border-color: transparent #86d8a7 transparent transparent; }
    .sm-blue.sm-vertical {
      -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3); }
      .sm-blue.sm-vertical a {
        padding: 9px 23px; }
        .sm-blue.sm-vertical a span.sub-arrow {
          top: 50%;
          margin-top: -5px;
          bottom: auto;
          left: auto;
          margin-left: 0;
          right: 10px;
          border-style: dashed dashed dashed solid;
          border-color: transparent transparent transparent #86d8a7; }
      .sm-blue.sm-vertical > li:first-child > a, .sm-blue.sm-vertical > li:first-child > :not(ul) a {
        -webkit-border-radius: 8px 8px 0 0;
        -moz-border-radius: 8px 8px 0 0;
        -ms-border-radius: 8px 8px 0 0;
        -o-border-radius: 8px 8px 0 0;
        border-radius: 8px 8px 0 0; }
      .sm-blue.sm-vertical > li:last-child > a, .sm-blue.sm-vertical > li:last-child > :not(ul) a {
        -webkit-border-radius: 0 0 8px 8px;
        -moz-border-radius: 0 0 8px 8px;
        -ms-border-radius: 0 0 8px 8px;
        -o-border-radius: 0 0 8px 8px;
        border-radius: 0 0 8px 8px; }
      .sm-blue.sm-vertical > li {
        border-left: 0 !important; }
      .sm-blue.sm-vertical ul {
        -webkit-border-radius: 4px !important;
        -moz-border-radius: 4px !important;
        -ms-border-radius: 4px !important;
        -o-border-radius: 4px !important;
        border-radius: 4px !important; }
        .sm-blue.sm-vertical ul a {
          padding: 9px 23px; } }

@media (min-width: 1200px) {
  #main-menu li.active > a {
    background: #2a824d; }
  #main-menu li.active > ul {
    display: block !important;
    width: 1000px !important; }
    #main-menu li.active > ul li {
      float: left !important; }
      #main-menu li.active > ul li a {
        padding: 9px 13px !important; } }

a {
  color: #000; }

.left {
  float: left; }

.right {
  float: right; }

.mp-o {
  margin: 2px 0;
  padding: 2px 0; }

.center {
  text-align: center; }

.block {
  width: 100%;
  box-sizing: content-box; }
  .block .pod-block {
    margin: 0 auto;
    position: relative;
    box-sizing: content-box; }
    @media only screen and (max-width: 479px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 980px) {
      .block .pod-block {
        max-width: 1200px; } }

.left-50 {
  width: 49%;
  float: left; }
  .left-50 .padding {
    padding: 2% 1% 0% 3%; }
  @media only screen and (max-width: 479px) {
    .left-50 {
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .left-50 {
      float: none;
      width: 100%; } }

.right-50 {
  width: 49%;
  float: right; }
  .right-50 .padding {
    padding: 2% 0% 0% 1%; }
  @media only screen and (max-width: 479px) {
    .right-50 {
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .right-50 {
      float: none;
      width: 100%; } }
  .right-50 p {
    line-height: 130%; }

/* .right-content */
.tur-top {
  margin-bottom: 20px; }

.left-30 {
  width: 29%;
  float: left; }
  .left-30 .padding {
    padding: 2% 1% 0% 3%; }
  @media only screen and (max-width: 479px) {
    .left-30 {
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .left-30 {
      float: none;
      width: 100%; } }

.right-70 {
  width: 69%;
  float: right; }
  .right-70 .padding {
    padding: 2% 0% 0% 1%; }
  @media only screen and (max-width: 479px) {
    .right-70 {
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .right-70 {
      float: none;
      width: 100%; } }

/* .right-content */
.center-100 .padding {
  padding: 20px; }
  @media only screen and (max-width: 479px) {
    .center-100 .padding {
      padding: 10px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .center-100 .padding {
      padding: 10px; } }

/*  .center-100  */
.left-content {
  width: 29%;
  float: left; }
  .left-content .padding {
    padding: 2% 1% 0% 3%; }
  @media only screen and (max-width: 479px) {
    .left-content {
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .left-content {
      float: none;
      width: 100%; } }

.right-content {
  width: 69%;
  float: right; }
  .right-content .padding {
    padding: 2% 0% 0% 1%; }
  @media only screen and (max-width: 479px) {
    .right-content {
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .right-content {
      float: none;
      width: 100%; } }
  .right-content p {
    line-height: 130%; }

/* .right-content */
.images-100 {
  max-width: 100%; }

.title {
  text-align: center;
  margin-bottom: 30px; }

.title h1 {
  font-size: 24px;
  color: #000;
  margin-bottom: 20px; }
  .title h1 hr {
    height: 1px;
    border: none;
    color: #717171;
    background: #717171;
    margin: 0 auto;
    width: 70%; }
  .title h1 i {
    display: block;
    height: 30px; }
  .title h1 b {
    display: inline-block; }

.title h2 {
  font-size: 20px;
  color: #000;
  margin-bottom: 20px; }

.title-main {
  background: #07d357;
  margin-bottom: -40px; }

.title-main h2 {
  color: #fff;
  padding: 10px 0px 5px 0px; }

/* .title > hr { text-align: center;
height: 1px;
border: none;
color: #717171;
background: #717171;
margin: 0 auto;
width:120px;
} */
.block-text p {
  color: #989898;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 10px; }
  @media only screen and (max-width: 479px) {
    .block-text p {
      font-size: 13px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .block-text p {
      font-size: 13px; } }

.center-text p {
  color: #000;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 10px; }
  @media only screen and (max-width: 479px) {
    .center-text p {
      font-size: 13px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .center-text p {
      font-size: 13px; } }

.center-text h1 {
  font-size: 21px;
  margin-bottom: 10px; }

.center-text h2 {
  font-size: 18px;
  margin-bottom: 8px; }

.center-text h3 {
  font-size: 16px;
  margin-bottom: 6px; }

#bottom-link {
  text-align: center;
  margin: 40px 0px; }
  #bottom-link a {
    display: inline-block;
    margin: 0px 20px 20px 20px;
    padding: 30px 40px;
    border: 1px solid #d8b161;
    color: #d8b161;
    font-size: 30px; }
    #bottom-link a:hover {
      border: 1px solid #989898;
      color: #989898; }

/* #bottom-link */
.font-null {
  font-size: 0; }

span.skype_c2c_container img.skype_c2c_logo_img {
  display: none !important; }

.main-block {
  position: absolute;
  bottom: 200px;
  left: 0px;
  width: 100%;
  z-index: 90; }
  @media only screen and (max-width: 479px) {
    .main-block {
      bottom: 150px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .main-block {
      bottom: 150px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .main-block {
      bottom: 150px; } }

.main-block-podblock {
  width: 580px;
  background: rgba(0, 0, 0, 0.6);
  margin: 100px auto 0px auto;
  border-radius: 20px; }
  @media only screen and (max-width: 479px) {
    .main-block-podblock {
      width: 90%;
      margin: 0 5%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .main-block-podblock {
      width: 90%;
      margin: 0 5%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .main-block-podblock {
      width: 90%;
      margin: 0 5%; } }
  .main-block-podblock .padding {
    padding: 0px 20px; }

.main-block-logo {
  margin-top: 40px;
  text-align: center; }

.main-block-logo img {
  max-width: 100%; }

.main-block-text {
  margin-top: 40px;
  color: #fff;
  text-align: center; }
  .main-block-text p {
    line-height: 130%; }

.main-block-menu {
  margin-top: 40px;
  text-align: justify;
  /* Лекарство для IE6-7*/
  text-justify: newspaper; }

.main-block-menu a {
  display: inline-block;
  line-height: 130%;
  margin: 0 15px 0 15px;
  padding: 10px 20px;
  color: #d8b161;
  border: 1px solid #d8b161;
  font-size: 18px; }
  @media only screen and (max-width: 479px) {
    .main-block-menu a {
      display: block;
      width: 70%;
      margin: 0 5% 20px 5%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .main-block-menu a {
      display: block;
      width: 50%;
      margin: 0 5% 20px 5%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .main-block-menu a {
      display: block;
      width: 40%;
      margin: 0 5% 20px 5%; } }

.main-block-menu a:hover {
  color: #fff;
  border: 1px solid #fff; }

.main-block-menu a.hidden {
  width: 100%;
  height: 0;
  visibility: hidden; }

#breadcrumb {
  text-align: center;
  color: #989898;
  margin: 40px 0px; }
  #breadcrumb .padding {
    padding: 0px 20px; }
    @media only screen and (max-width: 479px) {
      #breadcrumb .padding {
        padding: 0px 10px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #breadcrumb .padding {
        padding: 0px 10px; } }
  #breadcrumb .breadcrumb-center {
    display: inline-block;
    line-height: 130%; }
  #breadcrumb a {
    color: #989898;
    text-decoration: underline; }
    #breadcrumb a:hover {
      text-decoration: none; }
