#h1-text {

.padding {padding: 20px 20px 50px 20px; }


} /* #about */


#catalog-mest {

.padding {padding-bottom:20px;}

 ul {font-size: 0; margin-bottom: 40px; font-size: 0px;}
 ul li {display: inline-block; width:calc(20% - 22px); margin:10px; position:relative; vertical-align: top;
			background: #fff; border:1px solid #313131;
				
				// < 479
				@include respond-to(handhelds) { width:calc(100% - 22px); }
				// 480 > 599
				@include respond-to(wide-handhelds) { width:calc(50% - 22px); }
				// 600 > 767
				@include respond-to(small-tablets) { width:calc(33.3% - 22px); }
				// 768 > 979
				@include respond-to(tablets) { width:calc(25% - 22px); }
				// 980 < 
				@include respond-to(display) {  }
				// 1200 < 
				@include respond-to(display-big) { }

img {max-width: 100%;}

h3 {position: absolute; bottom: 0px; left:0px; width: calc(100% - 20px); padding:8px 10px; 
background: rgba(88,207,136,0.8);

font-size: 14px; color:#fff; text-align: center;
}

} // ul li


} // #catalog-mest

#catalog {

.padding {padding-bottom:20px;}

 ul {font-size: 0; margin-bottom: 40px; font-size: 0px;}
 ul li {display: inline-block; width:calc(25% - 22px); margin:10px; position:relative; vertical-align: top;
			background: #fff; border:1px solid #313131;
				
				// < 479
				@include respond-to(handhelds) { width:calc(100% - 22px); }
				// 480 > 599
				@include respond-to(wide-handhelds) { width:calc(50% - 22px); }
				// 600 > 767
				@include respond-to(small-tablets) { width:calc(50% - 22px); }
				// 768 > 979
				@include respond-to(tablets) { width:calc(33.3% - 22px); }
				// 980 < 
				@include respond-to(display) {  }
				// 1200 < 
				@include respond-to(display-big) { }





.skidki-line {position: absolute; top:-4px; right:-4px; width:100px; height:100px; 
background: url('../img/skidki-orange-text.png') left center no-repeat; background-size:contain;
}


.price-line {background:rgba(96,175,94,1.0); line-height: 130%; width: 80%; z-index: 2; @include clearfix; position: absolute; bottom:-10px; margin:0px 10%;
	font-size: 14px; color:#fff; 

&.price-line-center { width: 60%; margin:0px 20%; text-align: center;

.price {float:none; padding: 5px;}

}

	.price-old {float:left;  padding: 5px; text-decoration: line-through;}
	.price {float:right; padding: 5px;}
 }	

 a.cart-images {position: relative; display: block;}			

a.cart-title {padding:10px 10px 0px 10px; display: block;}


h4 {   font-family: "RobotoBold"; line-height:130%; font-size:12px;  font-weight:normal; text-transform: uppercase;  color: #000;
 		text-align:center; padding:15px 0px 15px 0px; 	




	@include respond-to(handhelds) { font-size: 14px; 
	 }
	@include respond-to(small-handhelds) { font-size: 14px;   }
	@include respond-to(wide-handhelds) { }
	@include respond-to(small-tablets) { }


}

.info-line {font-size: 0px; padding: 0px 0px 10px 0px;}
.info-line-center {text-align: center;}

.info, .date, .info-icon, .date-icon {font-size: 14px; line-height: 130%; display: inline-block; vertical-align: top; }

.info-icon {background: url('../img/place-icon.png') left center no-repeat; background-size:contain; width:20px; height:26px; margin-left:10px;}
.date-icon {background: url('../img/date-icon.png') left center no-repeat; background-size:contain; width:18px; height:18px; }

.info { padding-left: 10px;  width: calc(65% - 45px); margin-right: 5px;

a {color: #60af5e;

&:hover {text-decoration: underline;}
}

}
.date { padding: 0px 10px 0px 5px; width: calc(35% - 35px);}
 
} // li

 ul li:hover {

}


 ul li a:hover h4 {color:#60af5e;}
 ul li a img {max-width: 100%;}




} /*  #category */


#bottom-text { 

.pod-block {background: #fff;}

.padding {padding:10px 20px 30px 20px;

	@include respond-to(handhelds) { padding:10px 10px 20px 10px; 
	 }
	@include respond-to(small-handhelds) { padding:10px 10px 20px 10px;  }

 }


}


.text-col-line-3 { padding:0px 10px;
	
	width:calc(100% - 20px); /*указываем общую ширину блока с колонками*/
text-align:justify; /*выравнивание текста внутри колонок*/
column-count: 3; /*количество колонок, на которое хотим разбить текст*/
-moz-column-count: 3; /*для мозилы*/
-webkit-column-count: 3; /*для webkit браузеров*/
column-gap: 20px; /*отступ между колонками*/
-moz-column-gap: 20px;
-webkit-column-gap: 20px;
/* column-rule: 1px solid #000; если требуется по дизайну, разделяем колонки линией
-moz-column-rule: 1px solid #000;
-webkit-column-rule: 1px solid #000;*/

				@include respond-to(handhelds) { column-count: 1; margin:0 0 40px 0;}
				@include respond-to(wide-handhelds) { column-count: 1; }
				@include respond-to(small-tablets) { column-count: 2;}
				@include respond-to(tablets) { column-count: 2;}


	img {max-width:100%; margin-bottom:18px;}
	h2 {font-family: "RobotoBold";  font-size:16px; font-weight:normal; margin-bottom:18px; line-height:130%;
	b {font-weight:normal;}
	}
	p {font-family: "RobotoRegular";  font-size:14px; font-weight:normal; margin-bottom:16px; line-height:130%;}

	}


.text-col-line-1 { padding:0px 10px;
	
	width:calc(100% - 20px); /*указываем общую ширину блока с колонками*/
text-align:justify; /*выравнивание текста внутри колонок*/

table {

    width: 100%;
    border: 1px solid #ddd;
    border-right-width: 0;
    border-bottom-width: 0;
    border-spacing: 0;
    border-collapse: collapse;

	th, td {
	font-size:13px;
    padding: 0.5em 10px;
    border: 1px solid #ddd;
    border-left-width: 0;
    border-top-width: 0;
vertical-align: middle;

	b {font-weight:normal;}
		strong {font-weight:normal;}

		}

} // table


	img {max-width:100%; margin-bottom:18px;}

h1 {font-family: "RobotoBold";  font-size:18px; font-weight:normal; margin-bottom:20px; line-height:130%;
	b {font-weight:normal;}
	}


	h2 {font-family: "RobotoBold";  font-size:16px; font-weight:normal; margin-bottom:18px; line-height:130%;
	b {font-weight:normal;}
	}
	p {font-family: "RobotoRegular";  font-size:14px; font-weight:normal; margin-bottom:16px; line-height:130%;}


 a {color:#FF9000; font-size:14px;
	b {font-weight:normal;}
	strong {font-weight:normal;}

 }


	} // .text-col-line-1