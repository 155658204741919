#otzuvu {


	.padding {padding: 20px 20px 0px 20px}


	a.otzuv-btn {
				
				background: #fa991b;
				padding: 5px;
				display: inline-block;
				margin-bottom: 40px;
				color: white;
				text-shadow: 0 -1px 0 rgba(0,0,0,0.2);
				border: 1px solid #d37700;
			}

	

	
	.otzuvu-block { @include clearfix;

		width: 100%; 
		margin: 0 0 30px 0;


		.otzuvu-block-header {margin-bottom: 10px;}
		.otzuvu-block-rate, .otzuvu-block-date, .otzuvu-block-name {
			display: inline-block; 
			margin-right: 5px;
		}

		.otzuvu-block-rate {
			width: 84px; 
			height:15px; 
			background: url(../img/rate-icon.png);
			background-repeat:no-repeat; 
			background-position: 0px 0px;
			&-1 {background-position: 0px -15px;}
			&-2 {background-position: 0px -30px;}
			&-3 {background-position: 0px -45px;}
			&-4 {background-position: 0px -60px;}
			&-5 {background-position: 0px -75px;}			
		}

		.otzuvu-block-date {font-size: 12px; }
		.otzuvu-block-name h3 {
			font-size: 16px;
			a {color: #fa991b; text-decoration: none;
				&:hover {text-decoration: underline;}
			}
		}

		.otzuvu-block-content, .otzuvu-block-content p {
		font-size: 14px;
		font-weight: normal;
		line-height: 120%;

		margin: 0 0 20px 0;
		}

		

		.gallery {

.owl-item {margin:0px 1px;}

 ul.cart-gallery {font-size: 0; margin-bottom: 40px;}
 ul.cart-gallery li {

display: inline-block; margin:1px; width:calc(25% - 8px);

				// < 479
				@include respond-to(handhelds) { width:calc(100% - 2px); }
				// 480 > 599
				@include respond-to(wide-handhelds) { width:calc(50% - 2px); }
				// 600 > 767
				@include respond-to(small-tablets) { width:calc(50% - 2px); }
				// 768 > 979
				@include respond-to(tablets) { width:calc(50% - 2px); }
				// 980 < 
				@include respond-to(display) { width:calc(33.3% - 6px); }
				// 1200 < 
				@include respond-to(display-big) { width:calc(33.3% - 6px); }


}

 ul.cart-gallery li img {max-width: 100%;}
 ul.cart-owl li img {max-width: 100%;}

.show-all-gallery {text-align: center; margin: 20px 0px;
&.active {display: none;}
}
.show-all-gallery a {color:#FF9000; text-decoration: underline; padding: 10px 20px;

&:hover {text-decoration: none;}
 }

.show-all-gallery.active {display: none;}

ul.cart-gallery li a,  ul.cart-owl li a {position:relative; display:block;}
ul.cart-gallery li a span,  ul.cart-owl li a span {position: absolute; width: 100%; height: 100%; background: url(../img/play-button.png) center center; background-size: cover; transition: all 0.3s linear;
&:hover {background: url(../img/play-button.png) center center; background-size: cover; transition: all 0.3s linear;}

}

.cart-gallery {display: none;}
.cart-gallery.open {display: block;}

.cart-owl.close {display: none!important;}

} // .gallery

		// < 479
		@include respond-to(handhelds) {  }

		// 480 > 599
		@include respond-to(wide-handhelds) { }
		// 600 > 767
		@include respond-to(small-tablets) {  }

	} // otzuvu-block
} // #otzuvu