
#header-top {  background: #00b74a;



        // < 479
        @include respond-to(handhelds) { }
        // 480 > 599
        @include respond-to(wide-handhelds) {  min-height: 158px; }
        // 600 > 767
        @include respond-to(small-tablets) { min-height: 158px; }
        // 768 > 979
        @include respond-to(tablets) { min-height: 135px; }
        // 980 < 
        @include respond-to(display) { min-height: 135px; }
        // 1200 < 
        @include respond-to(display-big) { min-height: 102px; }



.pod-block {min-height: 102px; }

.header-logo { 

float:left; max-width: 100%; margin: 5px 0px 5px 40px;


        // < 479
        @include respond-to(handhelds) { float:none; margin:0px; padding: 20px 0px 20px 20px; text-align:center;}
        // 480 > 599
        @include respond-to(wide-handhelds) { float:left; width: calc(40% - 20px);  margin:0px; padding: 20px 0px 20px 20px; text-align:center; }
        // 600 > 767
        @include respond-to(small-tablets) { float:left; width: calc(50% - 20px); margin:0px; padding: 20px 0px 20px 20px; text-align:center; }


img {max-width: 100%; max-height: 90px;}


}


.menu-top { position: absolute; top: 0px; left: 40%;


        // < 479
        @include respond-to(handhelds) { float:none; position: relative; top:auto; left:auto; width: calc(100% - 20px); text-align: center; padding: 0px 10px 0px 10px; }
        // 480 > 599
        @include respond-to(wide-handhelds) {float:none; position: relative; top:auto; left:auto; width: calc(100% - 20px); text-align: center; padding: 0px 10px 0px 10px;}
        // 600 > 767
        @include respond-to(small-tablets) {float:none; position: relative; top:auto; left:auto; width: calc(100% - 40px); text-align: center; padding: 0px 20px 0px 20px; }
        // 768 > 979
        @include respond-to(tablets) {  }
        // 980 < 
        @include respond-to(display) {  }
        // 1200 < 
        @include respond-to(display-big) {  }



li {display: inline-block; margin: 0px 20px 0px 0px;  border-top:2px solid #00b74a;

  &.active, &:hover {border-top:2px solid #fff;}

a { font-family: "RobotoLight"; line-height:130%; font-size:16px;  font-weight:normal; color:#fff; }



} // li


} //.menu-top


.header-info { float:right;  min-width: 280px; text-align: right; padding: 5px 10px 0px 10px; 

  font-family: "RobotoLight"; line-height:130%; font-size:14px;  font-weight:normal; color:#fff;


        // < 479
        @include respond-to(handhelds) { float:none; position: relative; top:auto; left:auto; width: calc(100% - 20px); text-align: center; padding: 10px 10px 10px 10px; }
        // 480 > 599
        @include respond-to(wide-handhelds) { margin-top: 20px; float:right;  position: relative; top:auto; left:auto; width: calc(60% - 20px); text-align: center; padding: 10px 10px 10px 10px; min-width: auto; background:none;}
        // 600 > 767
        @include respond-to(small-tablets) { margin-top: 20px; float:right;  position: relative; top:auto; left:auto; width: calc(50% - 40px); text-align: center; padding: 10px 10px 10px 10px; min-width: auto; background:none;}
        // 768 > 979
        @include respond-to(tablets) { margin-top: 30px; background:none; }
        // 980 < 
        @include respond-to(display) { margin-top: 30px; background:none;}
        // 1200 < 
        @include respond-to(display-big) { margin-top: 0px; background: rgba( 0, 0, 0, 0.1); }


img, span {display: inline-block; vertical-align: middle;} 

p {margin:0px 0px 5px 0px;}

.header-info-tel { @include clearfix; }

 .header-info-tel-left {float: left; width:50%;} 
 .header-info-tel-right {float: right; width:50%;} 

}

.header-info-adress {}


} // #header-top

#header-menu {min-height: 52px; background: #309559;}


.menu-left {

        @include respond-to(display) { width:70%; float:left;}

}




.menu-right {float:right; width:30%; text-align: right;

        // < 479
        @include respond-to(handhelds) { float:none; width:calc(100% - 20px); background: #309559; padding: 5px 10px; text-align: center;}
        // 480 > 599
        @include respond-to(wide-handhelds) { float:none; width:calc(100% - 20px); background: #309559; padding: 5px 10px;  text-align: center;}
        // 600 > 767
        @include respond-to(small-tablets) { float:none; width:100%; background: #309559; padding: 5px 10px;  text-align: center; }
         @include respond-to(tablets) { float:none; width:100%; background: #309559; padding: 5px 10px;  text-align: center; }


ul {margin-top:10px;}
ul li {display: inline-block; font-size: 12px; padding: 5px 5px; 

  
&.active {background: #257a47;}

    a {color:#fff;



    padding: 5px; 

    img {margin-right: 5px;}

    img, span {display: inline-block; vertical-align: middle;}

    }



} // ul li


} // menu-right





#secrch-block { text-align: left; padding: 10px 20px; display: none;

width: 50%; 

        @include respond-to(handhelds) { width: calc(100% - 40px);}
                
                @include respond-to(wide-handhelds) { margin: 0px auto;}
                // 600 > 767
                @include respond-to(small-tablets) { margin: 0px auto;}
                // 768 > 979
                @include respond-to(tablets) {margin: 0px auto; }
                // 980 < 
                @include respond-to(display) { }


     &.active {display: block;}


 } // #secrch-block

#search {

                // 600 > 767
                @include respond-to(small-tablets) { }
                // 768 > 979
                @include respond-to(tablets) { }
                // 980 < 
                @include respond-to(display) { margin-top: 10px; }

}

.search {
  position: absolute;
  top: 76px;
  right: 10px;
  background: #fff;
  z-index: 101;
  padding: 5px 10px;
  box-shadow: 0px 0px 5px 2px #7B7878; }

  #secrch-block {position: relative;}

#search {
  width: 100%;
  font-size: 14px;
  border: 1px solid gainsboro;
  box-sizing: border-box;
  padding: 3px; }

#search:hover {
  border: 1px solid #707070; }

#search:focus {
  border: 1px solid #707070; }

#search-result {
    position: absolute;
    left:50%;
    top:36px; 
    width:50%;
    margin-left: -25%;
  box-sizing: border-box;


                // < 479
                @include respond-to(handhelds) { left:20px; margin-left: 0; width:calc(100% - 40px);  }
                // 480 > 599
                @include respond-to(wide-handhelds) { left:20px;  margin-left: 0; width:calc(100% - 40px); }
                @include respond-to(small-tablets) {   }
                // 768 > 979
                @include respond-to(tablets) { top:38px; margin-left: 0; left: 20px; width: calc(100% - 40px); }
                // 980 < 
                @include respond-to(display) { top:86px; margin-left: 0; left: 20px; width: calc(100% - 40px); }
                // 1200 < 
                @include respond-to(display-big) {margin-left: 0; left: 20px; width: calc(100% - 40px); }

 }

  .search-inner {
  background-color: #fff;
  z-index: 107;
  position: relative;
  transition: all 0.2s linear;
  border-top: none;
  box-shadow: 3px 5px 8px rgba(50, 50, 50, 0.6); }

.search-inner a {
  display: block;
  padding: 7px 10px;
  text-decoration: none;
  //transition: all 0.2s linear;
  font-size: 14px;
  color: #000;
  line-height: 130%; 

&:hover {

background: #309559;
        background-image: -owg(linear-gradient(to bottom, #36a865, #2a824d));
        background-image: -webkit(linear-gradient(to bottom, #36a865, #2a824d));
        background-image: -moz(linear-gradient(to bottom, #36a865, #2a824d));
        background-image: -o(linear-gradient(to bottom, #36a865, #2a824d));
        background-image: linear-gradient(to bottom, #36a865, #2a824d);
        color: #fff; 

}

} // .search-inner a

  a.template_napravlenie {color:#2b844e; font-size: 16px;}


.search-inner-OFF a:hover {
  background-color: #eee; }

.search-inner a:after {
  content: "\2192";
  float: right; }







.header-baner { background: url('../img/baner-center.jpg') center; min-height: 220px; position: relative;


.nadpis-center { width:360px; position: absolute; left:calc(50% - 180px); bottom: 0px; padding: 16px 32px; background: #36d474; 
font-family: "RobotoBold"; line-height:130%; font-size:16px;  font-weight:normal; color:#fff;
text-transform: uppercase;


                // < 479
                @include respond-to(handhelds) { width:calc(90% - 32px); padding: 8px 16px; font-size:14px; left:5%;}
                // 480 > 599
                @include respond-to(wide-handhelds) { width:calc(80% - 32px); padding: 8px 16px;  left:10%; font-size:14px; }

}

} //  .header-baner






#header.header-fon {background: url('../img/header.jpg') bottom center;}




#header{

	min-height: 370px;

	// Для телефонов
	@include respond-to(handhelds) { height: 220px;  }
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { height: 320px;  }
	// Для маленьких планшетов
	@include respond-to(small-tablets) { height: 320px;  }
	// Для планшетов
	@include respond-to(tablets) {  }
	// Для экранов
	@include respond-to(display) {  }



.pod-block{
	@include pod-block-100;

.padding {padding: 0px 20px;}



.header-logo {float:left; margin: 10px 0px;
		// Для телефонов
	@include respond-to(handhelds) { width:160px;  }
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { width:220px;  }
	// Для маленьких планшетов
	@include respond-to(small-tablets) { width:220px;  }
	// Для планшетов
	@include respond-to(tablets) { width:220px; }
  }	

.header-logo img {width: 100%;}
} //.pod-block


} /* #header*/




