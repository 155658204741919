#h1-text {

.padding {padding: 20px 20px 50px 20px; }


} /* #about */


.fotorama-sm {display:none;}

				// < 479
				@include respond-to(handhelds) { 
				.fotorama-big {display:none;}
				.fotorama-sm {display:block;}
				}
				// 480 > 599
				@include respond-to(wide-handhelds) {
				.fotorama-big {display:none;}
				.fotorama-sm {display:block;}
				 }




.cart-block { @include clearfix; 

&>div>.padding {padding:20px 0px;}


.top-header h1 {font-family: "RobotoBold";  font-size:24px; font-weight:normal; 
	margin-bottom:18px; margin-top: 10px; line-height:130%; }

.tour-info { margin-bottom: 5px; background: #fafafa; padding: 10px 10px 5px 10px;

ul {}
ul li { line-height: 130%; font-size: 16px; margin-bottom: 5px;

.icon {

position: relative;
    bottom: -1px;
    width: 16px;
    height: 16px;
    float: left;
    margin: 0 5px -5px 0;
    background: #FF9000 url(../img/colored_icons.png) 0 0 no-repeat;

&.icon-naprav {background-position: -6px -34px;}
&.icon-days {background-position: -6px -5px;}
&.icon-otprav {background-position: -6px -141px;}
&.icon-prib {background-position: -6px -167px;}
&.icon-cena {background-position: -7px -64px;}


}





a {color:#00af58; 
	&:hover {text-decoration: underline;}
}

}

} // .tour-info


.dop-info { margin-bottom: 5px;

p {font-size:14px; line-height: 130%; margin-bottom: 5px;}

} // .dop-info


h3.title-share {margin:20px 0px 10px 0px;}

.share42init {margin-bottom: 20px;}

.line-btn { font-size: 0px; 

.btn {

width:31%; display: inline-block; margin-right:2%;

}

.btn-bron a, .btn-oplata a, .btn-vopros a{display: block; font-size: 13px; padding:10px 5px; background: #00af58; color:#fff; text-transform:uppercase; line-height: 130%; text-align: center; }

 }


.link-tabs {

ul {font-size: 0; margin-left: 1px;}

ul li {display: inline-block; font-size: 14px;  margin-right: 2px; 
		a {padding:10px 20px; color:#000; border-top:2px solid #07d357; display: block;}

	&.active {  margin-bottom: -1px; a {border-top:none;  border-bottom:1px solid #fff; background: #fff;}   }

 }

  } // link-tabs

.tab-open { border: 1px solid #E5E5E5; background: #fff; margin-bottom: 20px;

&>.padding {padding:20px;}

} // tab-open


.gallery {

.owl-item {margin:0px 1px;}

 ul.cart-gallery {font-size: 0; margin-bottom: 40px;}
 ul.cart-gallery li {

display: inline-block; margin:1px; width:calc(25% - 8px);

				// < 479
				@include respond-to(handhelds) { width:calc(100% - 2px); }
				// 480 > 599
				@include respond-to(wide-handhelds) { width:calc(50% - 2px); }
				// 600 > 767
				@include respond-to(small-tablets) { width:calc(50% - 2px); }
				// 768 > 979
				@include respond-to(tablets) { width:calc(50% - 2px); }
				// 980 < 
				@include respond-to(display) { width:calc(33.3% - 6px); }
				// 1200 < 
				@include respond-to(display-big) { width:calc(33.3% - 6px); }


}

 ul.cart-gallery li img {max-width: 100%;}
 ul.cart-owl li img {max-width: 100%;}

.show-all-gallery {text-align: center; margin: 20px 0px;
&.active {display: none;}
}
.show-all-gallery a {color:#FF9000; text-decoration: underline; padding: 10px 20px;

&:hover {text-decoration: none;}
 }

.show-all-gallery.active {display: none;}

ul.cart-gallery li a,  ul.cart-owl li a {position:relative; display:block;}
ul.cart-gallery li a span,  ul.cart-owl li a span {position: absolute; width: 100%; height: 100%; background: url(../img/play-button.png) center center; background-size: cover; transition: all 0.3s linear;
&:hover {background: url(../img/play-button.png) center center; background-size: cover; transition: all 0.3s linear;}

}

.cart-gallery {display: none;}
.cart-gallery.open {display: block;}

.cart-owl.close {display: none!important;}

} // .gallery


} /*  #cart */


#bottom-text { 

.pod-block {background: #fff;}

.padding {padding:10px 20px 30px 20px;

	@include respond-to(handhelds) { padding:10px 10px 20px 10px; 
	 }
	@include respond-to(small-handhelds) { padding:10px 10px 20px 10px;  }

 }


}


.tur-text { background: #fff;

	p{font-size:14px;  margin: 0 0 1em 0; line-height: 1.5em; padding: 0;}
	a {color:#FF9000; text-decoration: underline; 
	&:hover {text-decoration: none;}
	}

h1 {
	font-family: "RobotoBold";   font-weight:normal; 
    font-size: 24px;
    margin-bottom: 0.6em;	
    line-height: 1em;
    color: #444;
}


h2 {
	font-family: "RobotoBold";   font-weight:normal; 
    font-size: 20px;
    margin-bottom: 0.6em;	
    line-height: 1em;
    color: #444;
}

h3 {
	font-family: "RobotoBold";   font-weight:normal; 
    font-size: 18px;
    margin-bottom: 0.7em;	
    line-height: 1em;
    color: #444;
}



		table {

    width: 100%;
    border: 1px solid #ddd;
    border-right-width: 0;
    border-bottom-width: 0;
    border-spacing: 0;
    border-collapse: collapse;

	th, td {
	font-size:13px;
    padding: 0.5em 10px;
    border: 1px solid #ddd;
    border-left-width: 0;
    border-top-width: 0;
		}

} // table
	

} // .tur-text


.tur-text-left {



} //.tur-text-left 


.tur-text-center {

h1 {    font-size: 1.9em;    margin-bottom: 0.5em;	}

} // .tur-text-center


.tab-text {

	p {font-size:13px;}

	} // .tab-text




ul.tab-gallery {font-size: 0; margin-bottom: 40px;

li {

display: inline-block; margin:1px; width:calc(25% - 8px);

				// < 479
				@include respond-to(handhelds) { width:calc(100% - 2px); }
				// 480 > 599
				@include respond-to(wide-handhelds) { width:calc(50% - 2px); }
				// 600 > 767
				@include respond-to(small-tablets) { width:calc(50% - 2px); }
				// 768 > 979
				@include respond-to(tablets) { width:calc(50% - 2px); }
				// 980 < 
				@include respond-to(display) { width:calc(33.3% - 6px); }
				// 1200 < 
				@include respond-to(display-big) { width:calc(33.3% - 6px); }


img {max-width: 100%;}

}


} // ul.tab-gallery









.tabs {
	margin: 0 0 30px;
}
.tabs__caption {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	list-style: none;
	position: relative;
	margin: -1px 0 0 -1px;
}
.tabs__caption li:last-child:before {
	content: '';
	position: absolute;
	bottom: -5px;
	left: 0;
	right: -2px;
	z-index: -1;
	height: 5px;
	background: #7DCA86;
}
.tabs__caption:after {
	content: '';
	display: table;
	clear: both;
}
.tabs__caption li {
	padding: 9px 15px;
	margin: 1px 0 0 1px;
	background: #61AF6A;
	color: #FFF;
	position: relative;
	border: solid #61AF6A;
	border-width: 2px 2px 0;
	border-radius: 5px 5px 0 0;
	text-align: center;

	a {color: #FFF;}
}
.tabs__caption li:not(.active) {
	cursor: pointer;
}
.tabs__caption li:not(.active):hover {
	background: #7DCA86;
	border-color: #7DCA86;
}

.tabs__caption .active {
	background: #FFF;
	color: #000;

	a {color: #000;}

	border-color: #7DCA86;
}
.tabs__caption .active:after {
	content: '';
	position: absolute;
	bottom: -5px;
	left: 0;
	right: 0;
	height: 5px;
	background: #FFF;
}
.tabs__content {
	display: none;
	background: #FFF;
	border: 2px solid #7DCA86;
	border-radius: 0 5px 5px 5px;
	padding: 7px 15px;
}
.tabs__content.active {
	display: block;
}

.vertical .tabs__caption {
	float: left;
	display: block;
}
.vertical .tabs__caption li {
	float: none;
	border-width: 2px 0 2px 2px;
	border-radius: 5px 0 0 5px;
}
.vertical .tabs__caption li:last-child:before {
	display: none;
}
.vertical .tabs__caption .active:after {
	left: auto;
	top: 0;
	right: -2px;
	bottom: 0;
	width: 2px;
	height: auto;
}
.vertical .tabs__content {
	overflow: hidden;
}

@media screen and (max-width: 650px) {

	.tabs__caption li {
		-webkit-flex: 1 0 auto;
		    -ms-flex: 1 0 auto;
		        flex: 1 0 auto;
	}
	.vertical .tabs__caption {
		float: none;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}
	.vertical .tabs__caption li {
		border-width: 2px 2px 0;
		border-radius: 5px 5px 0 0;
	}
	.vertical .tabs__caption li:last-child:before {
		display: block;
	}
	.vertical .tabs__caption .active:after {
		top: auto;
		bottom: -5px;
		left: 0;
		right: 0;
		width: auto;
		height: 5px;
		background: #FFF;
	}

}

.big-tab-img { margin:10px 0px 20px 0px;

img {max-width: 100%;}

}