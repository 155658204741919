/* Код для подключения шрифта в /css/stylename.css */

    @font-face {
            font-family: "RobotoRegular";
            src: url("../fonts/RobotoRegular/RobotoRegular.eot");
            src: url("../fonts/RobotoRegular/RobotoRegular.eot?#iefix")format("embedded-opentype"),
            url("../fonts/RobotoRegular/RobotoRegular.woff") format("woff"),
            url("../fonts/RobotoRegular/RobotoRegular.ttf") format("truetype");
            font-style: normal;
            font-weight: normal;
    }

    @font-face {
            font-family: "RobotoLight";
            src: url("../fonts/RobotoLight/RobotoLight.eot");
            src: url("../fonts/RobotoLight/RobotoLight.eot?#iefix")format("embedded-opentype"),
            url("../fonts/RobotoLight/RobotoLight.woff") format("woff"),
            url("../fonts/RobotoLight/RobotoLight.ttf") format("truetype");
            font-style: normal;
            font-weight: normal;
    }

    @font-face {
            font-family: "RobotoMedium";
            src: url("../fonts/RobotoMedium/RobotoMedium.eot");
            src: url("../fonts/RobotoMedium/RobotoMedium.eot?#iefix")format("embedded-opentype"),
            url("../fonts/RobotoMedium/RobotoMedium.woff") format("woff"),
            url("../fonts/RobotoMedium/RobotoMedium.ttf") format("truetype");
            font-style: normal;
            font-weight: normal;
    }

    @font-face {
            font-family: "RobotoBold";
            src: url("../fonts/RobotoBold/RobotoBold.eot");
            src: url("../fonts/RobotoBold/RobotoBold.eot?#iefix")format("embedded-opentype"),
            url("../fonts/RobotoBold/RobotoBold.woff") format("woff"),
            url("../fonts/RobotoBold/RobotoBold.ttf") format("truetype");
            font-style: normal;
            font-weight: normal;
    }