#stati {


	.padding {padding: 20px 20px 0px 20px}

	
	.stati-block { @include clearfix;

		width: 100%; margin: 0 0 60px 0;

		// < 479
		@include respond-to(handhelds) { margin-bottom:16%; }

		// 480 > 599
		@include respond-to(wide-handhelds) {display: inline-block; width: 44%; margin:0 4% 8% 0; }
		// 600 > 767
		@include respond-to(small-tablets) {display: inline-block; width: 44%; margin:0 4% 8% 0;  }



		.stati-block-left {

			float: left; width: 30%; margin: 0 5% 0 0;


				// < 479
				@include respond-to(handhelds) { float: none; width: 100%; margin:0 0 20px 0; }
				// 480 > 599
				@include respond-to(wide-handhelds) {float: none;  width: 100%; margin:0 0 20px 0; }
				// 600 > 767
				@include respond-to(small-tablets) {float: none;  width: 100%; margin:0 0 20px 0; }


			img {max-width: 100%}

		} //stati-block

		.stati-block-right{

			float: right; width: 65%;

				// < 479
				@include respond-to(handhelds) { float: none; width: 100%; }
				// 480 > 599
				@include respond-to(wide-handhelds) {float: none; width: 100%; }
				// 600 > 767
				@include respond-to(small-tablets) { float: none; width: 100%; }


			h2 {margin-bottom: 0.8em;}
			
			p {margin-bottom: 2em; line-height: 130%;

				// < 479
				@include respond-to(handhelds) { margin-bottom: 1.3em;  }
				// 480 > 599
				@include respond-to(wide-handhelds) {display:none; }
				// 600 > 767
				@include respond-to(small-tablets) { display:none; }

			}

			a.btn {
				
				background: #fa991b;
				padding: 5px;
				margin-bottom: 20px;
				color: white;
				text-shadow: 0 -1px 0 rgba(0,0,0,0.2);
				border: 1px solid #d37700;


			}




		} //stati-block-right





	} // stati-block














} // #stati