

#footer{ background: #60af5e;


	.pod-block{

		.padding {padding: 16px 10px 0px 16px; }

			min-height: 234px;

	&.footer-fon {background: url('../img/footer.jpg') bottom center;}

	@include pod-block-100;

}


.footer-top-line {font-size: 0px;}
.footer-block {display:inline-block; vertical-align: top; width: 23%; margin-right: 2%; 
line-height: 130%; font-size: 18px; color:#fff;

h3 {margin-bottom: 8px;}
a {color:#fff;}

	// Для телефонов
	@include respond-to(handhelds) { width: 96%; margin:0 2% 16px 2%; };
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { width: 44%;  margin:0 3% 32px 3%; };
	// Для маленьких планшетов
	@include respond-to(small-tablets) { width: 44%;  margin:0 3% 32px 3%; };
	// Для планшетов
	@include respond-to(tablets) { font-size: 16px; };
	// Для экранов
	@include respond-to(display) {  };

}

  


	// Для телефонов
	@include respond-to(handhelds) { };
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) {  };
	// Для маленьких планшетов
	@include respond-to(small-tablets) {  };
	// Для планшетов
	@include respond-to(tablets) {  };
	// Для экранов
	@include respond-to(display) {  };






}//#footer